<script lang="ts" setup>
import {computed, nextTick, useTemplateRef} from "vue";
import Textbox from "./Textbox.vue";
import Validation from "./Validation.vue";
import {useRules} from "../Validator";

type Props =
{
    disabled?: boolean;
    maximum?: number;
    minimum?: number;
    readonly?: boolean;
    rule?: string;
};
const props = withDefaults(defineProps<Props>(),
{
    disabled: false,
    maximum: Number.MAX_SAFE_INTEGER,
    minimum: 0,
    readonly: false
});
const values = defineModel<string[]>("values", {default: []});
const inputs = useTemplateRef("inputs");
const insert = (index: number) =>
{
    if(values.value.length < props.maximum)
    {
        const result = [];
        if(values.value.length === 0)
        {
            result.push("", "");
        }
        else
        {
            result.push(...values.value.slice(0, index + 1), "", ...values.value.slice(index + 1));
        }
        values.value = result;
        nextTick(() => inputs.value![index + 1]!.focus());
    }
};
const remove = (event: Event, index: number) =>
{
    const {minimum} = props;
    if(values.value.length > minimum && values.value[index] === "")
    {
        values.value = [...values.value.slice(0, index), ...values.value.slice(index + 1)];
        nextTick(() => inputs.value![index === 0 ? 0 : index - 1]!.focus());
        event.preventDefault();
    }
};
const select = (event: KeyboardEvent, index: number) =>
{
    if(event.shiftKey === false)
    {
        inputs.value![index]?.focus();
        event.preventDefault();
    }
};
const update = (index: number, value: string) =>
{
    values.value = values.value.length === 0 ? [value] : values.value.map((v, n) => n === index ? value : v);
};

const addable = computed(() => values.value.length < props.maximum);
const removable = (index: number) => values.value.length > props.maximum || values.value.length > props.minimum && values.value[index] === "";
</script>
<template>
    <div>
        <TransitionGroup enterActiveClass="transition-transform" enterFromClass="transform-scale-y-0" enterToClass="transform-scale-y-100" leaveActiveClass="transition-transform" leaveFromClass="transform-scale-y-100" leaveToClass="transform-scale-y-0">
            <div v-bind:key="index" v-for="(_, index) in Math.max(values.length, 1)">
                <Validation v-bind:validator="useRules(...rule === undefined ? [] : [rule])" v-bind:value="values[index]" v-slot="{error, validate}">
                    <form class="flex flex-grow-1 flex-items-center parent" v-bind:class="[disabled ? '' : '', index > 0 ? 'm-t-1' : '', rule === '' ? '' : error ? 'invalid' : 'valid']" v-on:submit.prevent="insert(index)">
                        <Textbox ref="inputs" type="text" v-bind:disabled="disabled" v-bind:readonly="readonly" v-bind:value="values[index]" v-on:update:value="(value) => (update(index, value), nextTick(() => validate(false)))" v-on:keydown.delete="(event: KeyboardEvent) => remove(event, index)" v-on:keydown.down="(event: KeyboardEvent) => select(event, index + 1)" v-on:keydown.up="(event: KeyboardEvent) => select(event, index - 1)"/>
                        <button class="b-none b-rd-50% box-border flex flex-items-center flex-justify-center flex-shrink-0 h-6 transition-background-color p-0 w-6" type="button" v-bind:aria-label="values[index] === '' ? 'Remove' : 'Add'" v-bind:class="[disabled || addable === false && removable(index) === false ? 'bg-opacity-50' : 'cursor-pointer', removable(index) ? 'bg-pink' : 'bg-green']" v-on:click="(event) => values[index] === '' ? remove(event, index) : insert(index)" v-if="minimum < values.length || values.length < maximum">
                            <span class="color-white text-body" v-bind:class="[removable(index) ? 'i-fa6-solid:minus' : 'i-fa6-solid:plus']"/>
                        </button>
                    </form>
                </Validation>
            </div>
        </TransitionGroup>
    </div>
</template>