import {type InjectionKey, type Ref, inject, provide} from "vue";
import {onBeforeRouteLeave} from "vue-router";

export interface Navigation
{
    disabled?: boolean;
    exact?: boolean;
    href: string;
    icon?: `i-${string}:${string}`;
    scopes?: Array<Scope>;
    text?: string;
}

export type Scope = "authenticated" | "administrator" | "subscriber";

const NavigationContext = Symbol() as InjectionKey<Ref<Navigation[]>>;

export function useNavigation()
{
    const navigation = inject(NavigationContext)!;
    onBeforeRouteLeave(() =>
    {
        navigation.value = [];
    });
    return navigation;
}

export function withNavigation(navigation: Ref<Navigation[]>)
{
    provide(NavigationContext, navigation);
}