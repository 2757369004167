<script lang="ts" setup>
import List from "./List.vue";

interface Props
{
    disabled?: boolean;
}
withDefaults(defineProps<Props>(),
{
    disabled: false
});
const toc = defineModel<[string, string, string, string, string]>("toc", {default: ["", "", "", "", ""]});
const labels =
[
    "Inputs",
    "Activities",
    "Outputs",
    "Outcomes",
    "Impact / Final Outcomes"
];

const update = (index: number, value: string) =>
{
    toc.value = [index === 0 ? value : toc.value[0], index === 1 ? value : toc.value[1], index === 2 ? value : toc.value[2], index === 3 ? value : toc.value[3], index === 4 ? value : toc.value[4]];
};
</script>
<template>
    <div>
        <div class="flex flex-col flex-items-stretch" v-bind:class="[['color-green', 'color-yellow', 'color-blue', 'color-pink', 'color-brown'][index], disabled ? 'opacity-50' : '']" v-for="(_, index) in toc">
            <div class="focus-within:shadow-lg grid grid-justify-center grid-cols-[minmax(min-content,1fr)] grid-rows-[min-content_1fr] hover:shadow-lg m-t-8 min-h-30 shadow">
                <div class="flex flex-justify-center m-t-[calc(-0.5lh-0.25rem)]">
                    <div class="bg-current box-border text-center p-x-4 p-y-1 max-w-60">
                        <div class="color-white">{{labels[index]}}</div>
                    </div>
                </div>
                <List class="color-black h-100% p-b-2" v-bind:aria-label="labels[index]" v-bind:disabled="disabled" v-bind:value="toc[index]" v-on:update:value="(value) => update(index, value)"/>
            </div>
            <div class="b-transparent b-1rem b-l-solid b-r-solid b-t-solid b-b-solid b-t-color-current flex-self-center h-0 w-0" v-if="index < 4"/>
        </div>
    </div>
</template>