<script lang="ts">
import {type InjectionKey, type MaybeRefOrGetter, inject, onMounted, onUnmounted, provide, ref, toValue, watch} from "vue";

interface Context
{
    add: (field: Field) => void;
    remove: (field: Field) => void;
    reset: () => void;
};
interface Field
{
    errors: () => MaybeRefOrGetter<string>[];
    indicate: () => void;
    validate: (indicating?: boolean) => boolean;
}
const ValidationContext = Symbol() as InjectionKey<Context | null>;
</script>
<script generic="T" lang="ts" setup>
interface Props
{
    validator?: (value?: T) => MaybeRefOrGetter<string>[];
    value?: T;
}
const props = withDefaults(defineProps<Props>(),
{
    validator: () => []
});

const context: Context =
{
    add: (field: Field) => fields.add(field),
    remove: (field: Field) => fields.delete(field),
    reset: () => reset()
};
const fields: Set<Field> = new Set();
const parent = inject(ValidationContext, null);
provide(ValidationContext, context);

const errors = () =>
{
    const {validator} = props;
    const parent = validator(props.value);
    const children = Array.from(fields).map(({errors}) => errors());
    const messages = [parent, ...children];
    return messages.flat();
};
const error = ref<MaybeRefOrGetter<string> | null>();
const indicate = () =>
{
    for(const {indicate} of fields)
    {
        indicate();
    }
    const messages = errors();
    const [message] = messages;
    error.value = message;
};
const reset = () =>
{
    if(parent !== null)
    {
        parent.reset();
    }
    error.value = null;
};

const validate = (indicating: boolean = false) =>
{
    const messages = errors();
    const valid = messages.length === 0;
    if(indicating)
    {
        indicate();
    }
    if(valid)
    {
        reset();
    }
    return valid;
};
watch(() => props.value, () => validate());

const field: Field = {errors, indicate, validate};
onMounted(() => parent?.add(field));
onUnmounted(() => parent?.remove(field));
defineExpose({error: toValue(error), validate});
</script>
<template>
    <slot v-bind:error="error" v-bind:validate="validate"/>
</template>
