import {computed, isProxy, isReactive, isRef, ref, toRaw} from "vue";

/**
 * Decorates the function to debounce it.
 *
 * @param fn the function to debounce.
 * @param delay the delay in milliseconds.
 * @returns the debounced function.
 */
export function debounce<A extends any[], P extends any>(delay: number, fn: (...args: A) => P)
{
    const {debounce} = debounceWithCancel(delay, fn);
    return debounce;
}

/**
 * Decorates the function to debounce it.
 *
 * @param fn the function to debounce.
 * @param delay the delay in milliseconds.
 * @returns the cancel and debounce functions.
 */
export function debounceWithCancel<A extends any[], P extends any>(delay: number, fn: (...args: A) => P)
{
    const timer = ref<number | null>(null);
    const cancelable = computed(() => timer.value !== null);
    const cancel = () =>
    {
        if(timer.value === null)
        {
            return false;
        }
        else
        {
            window.clearTimeout(timer.value);
            timer.value = null;
            return true;
        }
    };
    const debounce = (...args: A) => new Promise<P>((resolve) =>
    {
        const cloned = structuredClone(deepToRaw(args));
        cancel();
        timer.value = window.setTimeout(() =>
        {
            timer.value = null;
            resolve(fn(...cloned));
        }, delay);
    });
    return {cancel, cancelable, debounce};
}

export function deepToRaw<T extends Record<string, any>>(o: T): T
{
    const unref = (v: any): any =>
    {
        if(v === null)
        {
            return null;
        }
        else if(Array.isArray(v))
        {
            return v.map((item) => unref(item));
        }
        else if(isProxy(v) || isReactive(v) || isRef(v))
        {
            return unref(toRaw(v));
        }
        else if(typeof v === "object")
        {
            return Object.keys(v).reduce((o, key) => (o[key as keyof typeof o] = unref(v[key]), o), {} as T);
        }
        else
        {
            return v;
        }
    };
    return unref(o);
}