<script lang="ts" setup>
import AddCard from "../components/cards/AddCard.vue";
import CardsLayout from "../components/cards/CardsLayout.vue";
import MenuCard from "../components/cards/MenuCard.vue";
import ModalQuestion from "../components/ModalQuestion.vue";
import {awaitable} from "../components/Awaitable";
import {fetch} from "../API";
import {ref} from "vue";
import {useRouter} from "vue-router";
import {useSWR} from "../SWR";

interface Props
{
    projectId: string;
}
const props = withDefaults(defineProps<Props>(), {});

const {data: reports, mutate} = useSWR("/project/{projectId}/report/", {projectId: props.projectId}, {});
const router = useRouter();

const adding = ref(false);
const addReport = awaitable(adding, async () =>
{
    const {projectId} = props;
    const reportId = await fetch("post", "/project/{projectId}/report/", {projectId}, {}, null);
    await router.push(`/project/${projectId}/reports/${reportId}/`);
});

const gotoReport = (reportId: string) => router.push(`/project/${props.projectId}/reports/${reportId}/`);

const removing = ref(new Set<string>());
const remove = ref<null | Function>(null);
const removeReport = async (reportId: string) =>
{
    const {projectId} = props;
    remove.value = async () =>
    {
        remove.value = null;
        removing.value.add(reportId);
        try
        {
            await fetch("delete", "/project/{projectId}/report/{reportId}/", {projectId, reportId}, {}, null);
            reports.value = reports.value!.filter(({id}) => id !== reportId);
            await mutate();
        }
        finally
        {
            removing.value.delete(reportId);
        }
    };
};
</script>
<template>
    <h1>Create reports</h1>
    <p>Create sustainability or impact reports for stakeholders or customers using our WYSIWYG reporting engine.</p>
    <CardsLayout>
        <AddCard v-on:click="addReport" v-bind:loading="adding">
            <template v-slot:icon>
                <div class="i-mdi:file-document-outline"/>
            </template>
            <template v-slot:default>Add report</template>
        </AddCard>
        <MenuCard v-bind:loading="true" v-if="reports === undefined"/>
        <TransitionGroup enterActiveClass="transition-transform" enterFromClass="transform-scale-0" enterToClass="transform-scale-100" leaveActiveClass="transition-transform" leaveFromClass="transform-scale-100" leaveToClass="transform-scale-0">
            <MenuCard v-bind:key="id" v-bind:loading="removing.has(id)" v-bind:menu="[{action: () => removeReport(id), text: 'Delete report'}]" v-for="({id, title}) of reports" v-on:click="gotoReport(id)">
                <template v-slot:default>
                    <div class="text-6">{{title}}</div>
                </template>
            </MenuCard>
        </TransitionGroup>
    </CardsLayout>
    <ModalQuestion v-bind:visible="remove !== null" v-on:answer:yes="remove!()" v-on:update:visible="remove = null">
        <template v-slot:title>Are you sure you want to delete this report?</template>
        <template v-slot:text>Once deleted, you won’t be able to recover it.</template>
        <template v-slot:yes>Yes, delete it</template>
        <template v-slot:no>No, keep it</template>
    </ModalQuestion>
</template>