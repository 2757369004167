<script lang="ts" setup>
import {Frequencies, Types} from "../indicator/Indicator.vue";
import Action from "../components/Action.vue";
import Table from "../components/Table.vue";
import Tags from "../components/Tags.vue";
import Tex from "../components/Tex.vue";
import Time from "../components/Time.vue";
import {format} from "./components/Interval";
import {typeSafeObjectFromEntries} from "../components/TypeSafe";
import {useBreadcrumb} from "../Breadcrumb";
import {useRouter} from "vue-router";
import {useSWR} from "../SWR";

interface Props
{
    projectId: string;
}
const props = withDefaults(defineProps<Props>(), {});

const {data: indicators} = useSWR("/project/{projectId}/indicator/", {projectId: props.projectId}, {fields: ["id", "formula", "frequency", "name", "measurements", "tags", "type"]});
const router = useRouter();

const gotoMeasure = async (indicatorId: string) =>
{
    const {projectId} = props;
    await router.push(`/project/${projectId!}/measurement/${indicatorId}/`);
};
useBreadcrumb();
</script>
<template>
    <h1>Add data</h1>
    <p>Add data to existing indicators.</p>
    <Table class="m-t-3" selectable v-bind:columns="7" v-bind:proportions="[3, 1, 1, 1, 1, 1]" v-bind:rows="indicators" v-on:select="({id}) => gotoMeasure(id)">
        <template v-slot:header:1:ellipsis>Indicator</template>
        <template v-slot:header:2:ellipsis>Frequency</template>
        <template v-slot:header:3:ellipsis>Type</template>
        <template v-slot:header:4:ellipsis>Entries</template>
        <template v-slot:header:5:ellipsis>Latest</template>
        <template v-slot:header:6:ellipsis>Updated</template>
        <template v-slot:column:1:ellipsis="{row: {name, tags}}">
            <div class="flex flex-gap-3 flex-items-center flex-wrap">
                <div class="ellipsis font-bold">{{name}}</div>
                <Tags v-bind:limit="4" v-bind:tags="tags ?? []" v-if="tags?.length ?? 0"/>
            </div>
        </template>
        <template v-slot:column:2:ellipsis="{row: {frequency}}">
            <span>{{typeSafeObjectFromEntries(Frequencies)[frequency]}}</span>
        </template>
        <template v-slot:column:3:ellipsis="{row: {type, unit, units}}">
            <div class="flex flex-wrap">
                <template v-if="type === 'formula' || type === 'unit'">
                    <template v-if="type === 'formula'">
                        <template v-bind:key="index" v-for="(unit, index) of units">
                            <span v-if="unit === ''">count</span>
                            <Tex type="unit" v-bind:expression="unit" v-else/>
                            <span class="white-space-pre-wrap" v-if="index < units.length - 1">, </span>
                        </template>
                        <span class="text-middlegray p-x-1">→</span>
                    </template>
                    <span v-if="unit === ''">count</span>
                    <Tex type="unit" v-bind:expression="unit" v-else/>
                </template>
                <span v-else>{{typeSafeObjectFromEntries(Types)[type]}}</span>
            </div>
        </template>
        <template v-slot:column:4:ellipsis="{row: {measurements}}">
            <template v-if="measurements === 0">No entries</template>
            <template v-else-if="measurements === 1">1 entry</template>
            <template v-else>{{measurements}} entries</template>
        </template>
        <template v-slot:column:5:ellipsis="{row: {frequency, latest}}">
            <template v-if="latest === null"/>
            <template v-else>{{format(latest, frequency)}}</template>
        </template>
        <template v-slot:column:6:ellipsis="{row: {updated}}">
            <template v-if="updated === null"/>
            <Time v-bind:time="new Date(updated)" v-else/>
        </template>
        <template v-slot:column:7:ellipsis="{row: {id}}">
            <Action icon="i-material-symbols:edit-outline-rounded" label="Enter data" v-on:action="gotoMeasure(id)"/>
        </template>
        <template v-slot:placeholder v-if="indicators?.length === 0">
            <div class="flex flex-justify-center">
                <div class="color-lightgray">No indicators created yet</div>
            </div>
        </template>
    </Table>
</template>