<script lang="ts" setup>
import {computed, useAttrs} from "vue";
import {Layout} from "../Layout";
import PageLayout from "./PageLayout.vue";
import WizardLayout from "./WizardLayout.vue";
import {useRoute} from "vue-router";

const attrs = useAttrs();
const route = useRoute();
const layout = computed(() => route.matched.reduce((previous, {meta: {layout}}) => layout ?? previous, "page" as Layout));
</script>
<template>
    <PageLayout v-bind="attrs" v-if="layout === 'page'"/>
    <WizardLayout v-bind="attrs" v-else-if="layout === 'wizard'"/>
</template>