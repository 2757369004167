<script generic="N extends 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10, T" lang="ts" setup>

type Indices<N extends number> = N extends 1 ? 1 : N extends 2 ? 1 | 2 : N extends 3 ? 1 | 2 | 3 : N extends 4 ? 1 | 2 | 3 | 4 : N extends 5 ? 1 | 2 | 3 | 4 | 5 : N extends 6 ? 1 | 2 | 3 | 4 | 5 | 6 : N extends 7 ? 1 | 2 | 3 | 4 | 5 | 6 | 7 : N extends 8 ? 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 : N extends 9 ? 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 : N extends 10 ? 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 : never;
type Column<N extends number, T> = {[_ in `column:${Indices<N>}${"" | ":ellipsis"}`]: (_: {index: N; row: T}) => any};
type Header<N extends number> = {[_ in `header:${Indices<N>}${"" | ":ellipsis"}`]: (_: {}) => any};
type Placeholder = {"placeholder": (_: {}) => any};

interface Emits
{
    (event: "select", row: T): void;
}
const emit = defineEmits<Emits>();

interface Props
{
    columns: N;
    loading?: boolean;
    proportions?: number[];
    rows: T[] | undefined | null;
    selectable?: boolean;
}
type Slots = Column<N, T> & Header<N> & Placeholder;
withDefaults(defineProps<Props>(),
{
    loading: false,
    selectable: false
});
const slots = defineSlots<Slots>();
const select = (row: T) => emit("select", row);
</script>
<template>
    <div class="grid grid-gap-x-6 grid-auto-rows-[1fr] grid-rows-[auto]" v-bind:style="{gridTemplateColumns: proportions === undefined ? `repeat(${columns - 1}, minmax(0, 1fr)) min-content` : `${proportions.slice(0, columns).map((n) => `minmax(0, ${n}fr)`).join(' ')} ${columns - proportions.length > 0 ? `repeat(${columns - proportions.length}, min-content)` : ''}`, gridTemplateRows: rows === null || rows === undefined ? `min-content min-content` : `min-content repeat(${rows.length ?? 0}, 1fr) min-content`}">
        <div class="color-gray flex-items-center font-bold hidden sm-first-p-l-3 sm-flex sm-last-p-r-3 p-b-3" v-bind:key="column" v-for="column of columns">
            <div class="ellipsis w-100%" v-if="slots[`header:${column as Indices<N>}:ellipsis`]">
                <slot v-bind:name="`header:${column as Indices<N>}:ellipsis`"/>
            </div>
            <slot v-bind:name="`header:${column as Indices<N>}`" v-else/>
        </div>
        <div class="sm-hidden"/>
        <div class="b-t-1 b-t-lightgray b-t-solid grid grid-cols-1 grid-gap-3 grid-cols-[1fr_min-content] grid-auto-rows-[min-content] p-y-3 sm-grid sm-grid-auto-rows-auto sm-grid-cols-subgrid" v-bind:class="{'cursor-pointer hover-bg-verylightgray': selectable}" v-bind:key="index" v-bind:style="{gridColumn: `span ${columns}`}" v-on:click="select(row)" v-for="(row, index) of rows">
            <div class="flex sm-flex-items-center sm-grid-col-auto sm-first-p-l-3 sm-grid-row-auto sm-last-p-r-3" v-bind:class="proportions === undefined ? column === columns ? 'grid-col-[2] grid-row-[1] grid-items-start' : `${column === 1 ? 'grid-col-[1]' : 'grid-col-[1/3]'}` : column > proportions.length ? 'grid-col-[2] grid-row-[1] grid-items-start' : `${column === 1 ? 'grid-col-[1]' : 'grid-col-[1/3]'}`" v-bind:key="column" v-for="column of columns">
                <div class="ellipsis w-100%" v-if="slots[`column:${column as Indices<N>}:ellipsis`]">
                    <slot v-bind:index="index" v-bind:name="`column:${column as Indices<N>}:ellipsis`" v-bind:row="row"/>
                </div>
                <slot v-bind:index="index" v-bind:name="`column:${column as Indices<N>}`" v-bind:row="row" v-else/>
            </div>
        </div>
        <div class="b-t-1 b-t-lightgray b-t-solid flex flex-items-center flex-justify-center grid-col-[1/-1] p-3" v-if="loading || rows === null || rows === undefined">
            <span class="color-green i-svg-spinners:180-ring-with-bg text-5"/>
        </div>
        <div class="b-t-1 b-t-lightgray b-t-solid flex flex-items-center grid-col-[1/-1] p-3" v-else-if="slots['placeholder']">
            <slot name="placeholder"/>
        </div>
    </div>
</template>