<script lang="ts" setup>
import {type Navigation, withNavigation} from "../Navigation";
import {computed, ref} from "vue";
import Breadcrumb from "../components/Breadcrumb.vue";
import Logo from "./Logo.svg";
import ProfileBar from "./ProfileBar.vue";
import ResponsiveLayout from "./ResponsiveLayout.vue";
import {authorization} from "../Authorization";
import {breadcrumb} from "../Breadcrumb";
import {useRoute} from "vue-router";

const auth = computed(() => route.matched.reduce((previous, {meta: {auth}}) => auth === undefined ? previous : previous || auth, false));
const route = useRoute();
const home = computed(() => route.matched.reduce((previous, {meta: {home}}) => home === undefined ? previous : home(route), "/"));
const navigation1 = computed(() => route.matched.reduce<Navigation[]>((previous, {meta: {navigation}}) => navigation === undefined ? previous : navigation(route), []).filter(({scopes = []}) => scopes.every((scope) => authorization.value?.roles.map((role) => role.toString()).includes(scope))));

const navigation2 = ref<Navigation[]>([]);
withNavigation(navigation2);

const navigation = computed(() => [...navigation1.value, ...navigation2.value]);
</script>
<template>
    <ResponsiveLayout>
        <template v-slot:default>
            <router-view />
        </template>
        <template v-slot:logo="{hide}">
            <router-link class="flex flex-content-center hidden m-4 max-w-[calc(100%-2rem)]" v-bind:to="home" v-on:click="hide">
                <img alt="Cheeri logo" class="h-4 max-w-100%" v-bind:src="Logo"/>
            </router-link>
            <router-link class="box-border color-gray flex flex-items-center hover-color-green no-underline m-y-3 m-l-4 m-r-4 max-w-[calc(100%-2rem)]" v-bind:active-class="exact ? '' : 'color-green'" v-bind:class="[disabled ? 'color-gray pointer-events-none' : '', text === undefined ? 'flex-justify-center w-100%' : 'flex-justify-end']" v-bind:exact-active-class="exact ? 'color-green' : ''" v-bind:to="href" v-bind:key="index" v-for="({disabled, exact, href, icon, text}, index) in navigation" v-on:click="hide">
                <span class="block grid-col-[2] grid-row-[1] h-1.5rem w-1.5rem mr-2" v-bind:class="icon"/>
                <span class="block font-sans text-body lh-6 truncate">{{text}}</span>
            </router-link>
        </template>
        <template v-slot:mobile="{toggle}">
            <div class="i-mdi:menu text-8 h-8 w-8" v-on:click="toggle"/>
        </template>
        <template v-slot:profile>
            <ProfileBar v-if="auth"/>
        </template>
        <template v-slot:sidebar>
            <router-view name="sidebar"/>
        </template>
        <template v-slot:toolbar>
            <Breadcrumb v-bind:links="breadcrumb"/>
            <div id="toolbar">
                <router-view name="toolbar"/>
            </div>
        </template>
    </ResponsiveLayout>
</template>