import Indicators from "./Indicators.vue";
import Measure from "./Measure.vue";
import type {RouteRecordRaw} from "vue-router";
import TelegramQRCode from "../TelegramQRCode.vue";
import {id} from "../RouteId";

export const MeasurementRoute: RouteRecordRaw =
{
    children:
    [
        {
            components:
            {
                default: Indicators,
                sidebar: TelegramQRCode
            },
            path: "",
            props:
            {
                default: ({params: {projectId}}) => ({projectId})
            }
        },
        {
            children:
            [
                {
                    component: Measure,
                    path: "",
                    props: ({params: {indicatorId, projectId}}) => ({date: "latest", indicatorId, projectId})
                },
                {
                    component: Measure,
                    path: ":date([0-9]{4}-[0-9]{2}-[0-9]{2})/",
                    props: ({params: {date, indicatorId, projectId}}) => ({date, indicatorId, projectId})
                }
            ],
            meta:
            {
                breadcrumb: (texts) => texts["indicatorId"] ?? "Indicator"
            },
            path: `${id("indicatorId")}/`
        }
    ],
    meta:
    {
        breadcrumb: () => "Indicators"
    },
    path: "measurement/"

};