<script setup lang="ts">
import Tooltip from "./Tooltip.vue";

interface Emits
{
    (event: "action"): void;
}
const emit = defineEmits<Emits>();

interface Props
{
    icon: `i-${string}:${string}`;
    label: string;
}
defineProps<Props>();

const action = () => emit("action");
</script>
<template>
    <Tooltip>
        <template v-slot:default>
            <a class="link text-6" v-bind:aria-label="label" v-bind:class="icon" v-on:click.stop="action"/>
        </template>
        <template v-slot:tooltip>{{label}}</template>
    </Tooltip>
</template>