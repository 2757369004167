import {extractExpressionUnits, introduceUnspecifiedUnits} from "../../../backend/main/SymbolicMath";
import {createWorkerModule} from "./WorkerModule";
import {parse} from "mathjs";

function toTex(expression: string): string
{
    try
    {
        const node = parse(expression);
        const units = extractExpressionUnits([node]);
        introduceUnspecifiedUnits(units);
        const tex = node.toTex();
        return tex;
    }
    catch(e)
    {
        return "";
    }
}

export type TexWorkerModule =
{
    toTex: typeof toTex
};
createWorkerModule<TexWorkerModule>({toTex});
