<script lang="ts">
import {type RouteLocation, useRoute, useRouter} from "vue-router";
import CardsLayout from "../components/cards/CardsLayout.vue";
import type {Navigation} from "../Navigation";
import NavigationCard from "../components/cards/NavigationCard.vue";
import {computed} from "vue";
import {useBreadcrumb} from "../Breadcrumb";
import {useSWR} from "../SWR";

export const navigation = (route: RouteLocation) =>
{
    const {params: {projectId}} = route;
    const navigation: Array<Navigation & {description: string}> =
    [
        {
            description: "Return to the main project dashboard",
            exact: true,
            href: `/project/${projectId}/`,
            icon: "i-material-symbols:folder-outline",
            text: "Project home"
        },
        {
            description: "Enter details of your impact project",
            href: `/project/${projectId}/define/`,
            icon: "i-ic:outline-drive-file-rename-outline",
            text: "Define project"
        },
        {
            description: "Manage and measure self-defined and standardized indicators",
            href: `/project/${projectId}/indicator/`,
            icon: "i-mdi:target",
            text: "Manage indicators"
        },
        {
            description: "Add and update data in indicators.",
            href: `/project/${projectId}/measurement/`,
            icon: "i-mdi:database-edit-outline",
            text: "Add data"
        },
        {
            description: "Create and share surveys to collect data from third-parties.",
            href: `/project/${projectId}/survey/`,
            icon: "i-material-symbols:folder-data-outline-rounded",
            text: "Send surveys"
        },
        {
            description: "Get comprehensive insights of your project",
            href: `/project/${projectId}/analyze/`,
            icon: "i-material-symbols:bar-chart-4-bars-rounded",
            text: "Analyze impact"
        },
        {
            description: "Generate sustainability and impact reports",
            href: `/project/${projectId}/reports/`,
            icon: "i-mdi:file-document-outline",
            text: "Create reports"
        }
    ];
    return navigation;
};
</script>
<script lang="ts" setup>
interface Props
{
    projectId: string;
}
const props = withDefaults(defineProps<Props>(), {});
const {data: project} = useSWR("/project/{projectId}/", {projectId: props.projectId}, {fields: ["name"]});

const route = useRoute();
const router = useRouter();
const cards = computed(() => navigation(route).slice(1));
useBreadcrumb({projectId: () => project.value?.name});
</script>
<template>
    <h1>{{project?.name ?? "&nbsp;"}}</h1>
    <p>Follow the flow below to measure, analyze and report your sustainability and impact.</p>
    <CardsLayout>
        <NavigationCard v-bind:arrow="index < cards.length - 1" v-bind:key="index" v-on:click="router.push(href)" v-for="({description, href, text}, index) of cards">
            <template v-slot:title>{{text}}</template>
            <template v-slot:description>{{description}}</template>
        </NavigationCard>
    </CardsLayout>
</template>
