import {type LocationQuery, type LocationQueryRaw, type RouteLocation, type RouteRecordRaw, type RouterScrollBehavior, createRouter, createWebHistory} from "vue-router";
import type {Navigation, Scope} from "./Navigation";
import {AuthRoutes} from "./auth/AuthRoutes";
import Authorization from "./Authorization";
import type {Layout} from "./Layout";
import NotFound from "./NotFound.vue";
import {ProjectRoute} from "./project/ProjectRoute";
import {RespondentRoute} from "./respondent/RespondentRoute";
import {TemplateRoute} from "./templates/TemplateRoute";
import {useRouteBreadcrumb} from "./Breadcrumb";

declare module "vue-router"
{
    interface RouteMeta
    {
        auth?: boolean;
        breadcrumb?: (texts: Record<string, string | undefined>) => string;
        home?: (route: RouteLocation) => string;
        layout?: Layout;
        message?: string;
        navigation?: (route: RouteLocation) => Navigation[];
        scopes?: Array<Scope>;
        scrollback?: boolean;
    }
}

const routes: RouteRecordRaw[] =
[
    {
        path: "/",
        redirect: () =>
        {
            const authorization = Authorization.decode();
            if(authorization === null)
            {
                return "/signin/";
            }
            else
            {
                return "/project/";
            }
        }
    },
    ...AuthRoutes,
    TemplateRoute,
    ProjectRoute,
    RespondentRoute,
    {
        component: NotFound,
        name: "404",
        path: "/:catchAll(.*)"
    }
];

const scrollBehavior: RouterScrollBehavior = (from, to, savedPosition) => from.meta.keepAlive === true ? savedPosition ?? ({top: 0}) : undefined;
const history = createWebHistory();
const parseQuery = (query: string): LocationQuery =>
{
    const params = new URLSearchParams(query);
    const entries = Array.from(params.entries()).map<[string, string | string[]]>(([key, value]) => key.endsWith("[]") ? [key, [value]] : [key, value]).reduce<{[K: string]: string | string[]}>((a, [key, value]) => (value instanceof Array ? {...a, [key]: [...a[key] ?? [], ...value]} : {...a, [key]: value}), {});
    return entries;
};
const stringifyQuery = (query: LocationQueryRaw): string =>
{
    const entries = Object.entries(query).flatMap(([key, value]) => value instanceof Array ? value.map((v) => [key, v === null || v === undefined ? "" : v.toString()]) : [[key, value === null || value === undefined ? "" : String(value)]]);
    const params = new URLSearchParams(entries);
    return params.toString();
};
const router = createRouter({history, parseQuery, routes, scrollBehavior, sensitive: true, strict: true, stringifyQuery});
router.beforeEach((to, from) =>
{
    if(from.meta.message !== undefined && to.meta.message !== undefined)
    {
        to.meta.message = from.meta.message;
    }
    useRouteBreadcrumb(to, {});
});
router.beforeEach(async ({matched, path}) =>
{
    const authorization = Authorization.decode();
    const scopes = matched.reduce((set, {meta: {scopes = []}}) => scopes.reduce((set, scope) => set.add(scope), set), new Set<Scope>());
    if(authorization === null)
    {
        if(scopes.size > 0)
        {
            return `/signin/?redirect=${encodeURIComponent(path)}`;
        }
    }
    else if(scopes.has("subscriber"))
    {
        const {subscription} = authorization;
        if(subscription === "Expired")
        {
            return `/subscription/subscribe/?redirect=${encodeURIComponent(path)}`;
        }
    }
});

export default router;