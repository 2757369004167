import {createWorkerModule} from "./WorkerModule";
import {runExpression} from "../../../backend/main/SymbolicMath";

function execute(expression: string, variables: Record<string, {unit?: string; value: number | string}>)
{
    return runExpression(expression, variables);
}

export type FormulaRunnerWorkerModule =
{
    execute: typeof execute;
}
createWorkerModule<FormulaRunnerWorkerModule>({execute});