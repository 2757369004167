<script lang="ts" setup>
import {computed, onMounted, onUnmounted, watchEffect} from "vue";
import {disabled, saveFunction, working} from "./ReportToolbar.vue";
import type {Card} from "./ReportCards";
import Loader from "../components/Loader.vue";
import ReportEditor from "./ReportEditor.vue";
import type {ReportMode} from "./ReportMode";
import {onBeforeRouteLeave} from "vue-router";
import {useAutosave} from "../components/Autosave";
import {useSWR} from "../SWR";

interface Props
{
    id?: string;
    mode: ReportMode.Edit | ReportMode.View;
    projectId: string;
    reportId: string;
}
const props = withDefaults(defineProps<Props>(), {});
const {data: report, mutate} = useSWR("/project/{projectId}/report/{reportId}/", {projectId: props.projectId, reportId: props.reportId}, {});

const getCards = (): Card[] => report.value?.cards ?? [];
const setCards = (value: Card[]) =>
{
    const cards = value.map(({card, value}) =>
    {
        if(card === "ChartCard")
        {
            return {card, value: value!};
        }
        else if(card === "ImageCard" || card === "VideoCard")
        {
            return {card, value: value ?? [null, null]};
        }
        else if(card === "StatisticsCard")
        {
            return {card, value: value ?? [null, null, null]};
        }
        else
        {
            return {card, value: value ?? ""};
        }
    });
    report.value = {...report.value ?? {title: ""}, cards};
    autosave();
};
const cards = computed({get: getCards, set: setCards});

const getTitle = () => report.value?.title ?? "";
const setTitle = (title: string) =>
{
    report.value = {...report.value ?? {cards: []}, title};
    autosave();
};
const title = computed({get: getTitle, set: setTitle});

const {autosave, save, saving} = useAutosave("patch", "/project/{projectId}/report/{reportId}/", async (fetch) =>
{
    const {projectId, reportId} = props;
    await fetch({projectId, reportId}, {}, report.value!);
    await mutate();
});

onBeforeRouteLeave(async () => await save());
onMounted(() => saveFunction.value = () => save());
onUnmounted(() => saveFunction.value = () => {});

watchEffect(() => disabled.value = report.value === undefined);
watchEffect(() => working.value = saving.value);
</script>
<template>
    <Loader v-bind:loading="report === undefined">
        <keep-alive>
            <ReportEditor v-bind:id="id" v-bind:mode="mode" v-model:cards="cards" v-model:title="title" v-on:update:cards="autosave" v-on:update:title="autosave"/>
        </keep-alive>
    </Loader>
</template>