import {DateTime, type DateTimeUnit, Duration, Interval} from "luxon";
import type {IndicatorFrequency} from "../../API";

/**
 * Convert an indicator frequency and a date to a time interval.
 *
 * @param date the date to use as the start of the interval, or {@code null} to use the current date.
 * @param frequency the indicator frequency.
 * @returns the time interval.
 */
export function toInterval(date: string | null, frequency: IndicatorFrequency): Interval
{
    if(frequency === "P0")
    {
        const epoch = DateTime.fromMillis(0, {zone: "utc"});
        return Interval.fromDateTimes(epoch, epoch);
    }
    else
    {
        const units: Record<Exclude<typeof frequency, "P0">, DateTimeUnit> =
        {
            "P1D": "day",
            "P1M": "month",
            "P1W": "week",
            "P1Y": "year",
            "P3M": "quarter"
        };
        const unit = units[frequency];
        const now = DateTime.utc().startOf(unit);
        if(date === null)
        {
            return Interval.fromDateTimes(now, now.endOf(unit));
        }
        else
        {
            const t1 = now.endOf(unit);
            const t0 = t1.minus(Duration.fromObject({year: 3})).startOf(unit);
            const t = DateTime.fromISO(date, {zone: "utc"});
            if(t.isValid)
            {
                if(t < t0)
                {
                    return Interval.fromDateTimes(t0, t0.endOf(unit));
                }
                else if(t > t1)
                {
                    return Interval.fromDateTimes(t1, t1.endOf(unit));
                }
                else
                {
                    return Interval.fromDateTimes(t.startOf(unit), t.endOf(unit));
                }
            }
            else
            {
                return Interval.fromDateTimes(now.startOf(unit), now.endOf(unit));
            }
        }
    }
}

/**
 * Format a date using an indicator frequency.
 *
 * @param date the date to format.
 * @param frequency the indicator frequency.
 * @returns the formatted date, or {@code null} if the frequency is {@code "P0"}.
 */
export function format(date: string, frequency: IndicatorFrequency): string | null
{
    if(frequency === "P0")
    {
        return null;
    }
    else
    {
        const formats: Record<Exclude<typeof frequency, "P0">, string | [string, string]> =
        {
            "P1D": "MMMM dd",
            "P1M": "MMMM",
            "P1W": ["MMMM dd", "dd"],
            "P1Y": "yyyy",
            "P3M": "yyyy 'Q'q"
        };
        const formatsYYYY: Record<Exclude<typeof frequency, "P0">, string | [string, string]> =
        {
            "P1D": "yyyy-MM-dd",
            "P1M": "yyyy MMMM",
            "P1W": ["yyyy MMMM dd", "dd"],
            "P1Y": "yyyy",
            "P3M": "yyyy 'Q'q"
        };
        const time = DateTime.fromISO(date, {zone: "utc"});
        const format = time.year === DateTime.utc().year ? formats[frequency] : formatsYYYY[frequency];
        if(format instanceof Array)
        {
            const {end: t1, start: t0} = toInterval(date, frequency);
            const s = [t0, t1].map((t, index) => t!.toFormat(format[index]));
            return s.join(" - ");
        }
        else
        {
            return time.toFormat(format);
        }
    }
}