<script setup lang="ts">
import {type Template, useGHG} from "../GHG";
import {computed, onMounted, ref} from "vue";
import Action from "../../components/Action.vue";
import Button from "../../components/Button.vue";
import ButtonContainer from "../../components/ButtonContainer.vue";
import Dropdown from "../../components/Dropdown.vue";
import Field from "../../components/Field.vue";
import Table from "../../components/Table.vue";
import Textbox from "../../components/Textbox.vue";
import Validation from "../../components/Validation.vue";
import {filterTemplates} from "../TemplateFilter";
import {useWizardProgress} from "../../Wizard";

const ghg = useGHG();
const page = "fugitive";

const templates = ref<Template[]>();
onMounted(async () => templates.value = await ghg.value.templates);

const skip = () => ghg.value.next(page);
const next = () =>
{
    ghg.value.save(page, templates.value!, rows.value);
    skip();
};
useWizardProgress(() => ghg.value.progress(page));

const refrigerants = computed(() => templates.value === undefined ? [] : filterTemplates(templates.value, {}, "refrigerant"));

interface Row
{
    name: string;
    refrigerant: string | null;
}
const rows = ref<Row[]>([{name: "", refrigerant: null}]);

const add = () => rows.value.push({name: "", refrigerant: null});
const remove = (index: number) => rows.value.splice(index, 1);
</script>
<template>
    <Validation>
        <template v-slot:default="{validate}">
            <ButtonContainer>
                <template v-slot:default>
                    <h1>Add fugitive emissions</h1>
                    <p>Add all air conditioning, refrigeration systems, or fire extinguishers in your facilities.</p>
                    <Table v-bind:columns="3" v-bind:loading="templates === undefined" v-bind:rows="templates === undefined ? [] : rows">
                        <template v-slot:header:1:ellipsis>Fugitive emission source</template>
                        <template v-slot:header:2:ellipsis>Refrigerant type</template>
                        <template v-slot:column:1="{row}">
                            <Field class="flex-grow" rule="NonEmptyString" v-bind:value="row.name" v-slot="{id}">
                                <Textbox v-bind:id="id" v-model:value="row.name" placeholder="Airconditioner floor 1"/>
                            </Field>
                        </template>
                        <template v-slot:column:2="{row}">
                            <Field class="flex-grow" rule="NotNull" v-bind:value="row.refrigerant" v-slot="{id}">
                                <Dropdown v-bind:id="id" v-bind:items="refrigerants" v-model:selected="row.refrigerant"/>
                            </Field>
                        </template>
                        <template v-slot:column:3="{index}">
                            <div class="flex flex-gap-2 flex-justify-center w-12">
                                <Action icon="i-material-symbols:delete-outline-rounded" label="Delete" v-on:action="remove(index)" v-if="rows.length > 1"/>
                                <Action icon="i-fa6-solid:plus" label="Add" v-on:action="add()" v-if="index === rows.length - 1"/>
                            </div>
                        </template>
                    </Table>
                </template>
                <template v-slot:buttons>
                    <Button role="primary" type="button" v-bind:disabled="templates === undefined" v-on:click="validate(true) && next()">Continue</Button>
                    <a class="link" v-on:click="skip">Skip</a>
                </template>
            </ButtonContainer>
        </template>
    </Validation>
</template>
