<script lang="ts">
import {type Ref, computed, onActivated, ref, watch} from "vue";
import Action from "../components/Action.vue";
import Button from "../components/Button.vue";
import ButtonContainer from "../components/ButtonContainer.vue";
import Checkbox from "../components/Checkbox.vue";
import Field from "../components/Field.vue";
import Table from "../components/Table.vue";
import Tag from "../components/Tag.vue";
import Textbox from "../components/Textbox.vue";
import {awaitable} from "../components/Awaitable";
import {debounceWithCancel} from "../components/Debounce";
import {fetch} from "../API";
import {useBreadcrumb} from "../Breadcrumb";
import {useRouter} from "vue-router";
import {useSWR} from "../SWR";
import {useStringPreference} from "../components/Preference";
import {useWizardBreadcrumb} from "../Wizard";

export function useSearchURL(projectId: Ref<string>, scope: Ref<string | undefined>, templateIds: Ref<string[] | undefined>)
{
    const query = computed(() =>
    {
        const select = templateIds.value === undefined ? [] : templateIds.value.map((id) => `select[]=${id}`);
        const params =
        [
            ...scope.value === undefined ? [] : [`scope=${scope.value}`],
            ...select
        ];
        return params.join("&");
    });
    const router = useRouter();
    watch(query, async (query) => await router.replace(`/project/${projectId.value}/indicator/add/search/?${query}`));
    return query;
};
</script>
<script setup lang="ts">
interface Props
{
    projectId: string;
    scope?: string;
    templateIds?: string[];
}
const props = defineProps<Props>();
const router = useRouter();

const search = useStringPreference("search", "");
const debounced = ref(search);
const execute = () => debounced.value = search.value;

const {data: results} = useSWR("/template/search/", {}, () => ({fields: ["name" as const, "tags" as const], ...props.scope === undefined ? {} : {scope: Number(props.scope) as 1 | 2 | 3}, search: debounced.value}), {ttl: 30000});

const {cancel, cancelable, debounce} = debounceWithCancel(1000, () => execute());
watch(search, async () =>
{
    if(search.value === "")
    {
        cancel();
        results.value = [];
    }
    else
    {
        await debounce();
    }
});

const selected = ref<string[]>(props.templateIds ?? []);
const select = (checked: boolean, templateId: string) =>
{
    if(checked)
    {
        selected.value.push(templateId);
    }
    else
    {
        selected.value = selected.value.filter((id) => id !== templateId);
    }
};

const addable = computed(() => selected.value.filter((id) => results.value?.some(({id: templateId}) => templateId === id)));
const adding = ref(false);
const add = awaitable(adding, async () =>
{
    const {projectId} = props;
    const templates = addable.value.flatMap((select) => results.value!.filter(({id}) => id === select)).map(({id, name}) => ({id, name: name ?? ""}));
    await fetch("post", "/project/{projectId}/indicator/template/clone/", {projectId}, {}, templates);
    await router.push(`/project/${projectId}/indicator/`);
});

onActivated(() => selected.value = props.templateIds ?? []);

const query = useSearchURL(computed(() => props.projectId), computed(() => props.scope), addable);

const gotoPreview = async (id: string) => await router.push(`/project/${props.projectId}/indicator/add/search/${id}/?${query.value}`);

useBreadcrumb();
useWizardBreadcrumb();
</script>
<template>
    <ButtonContainer>
        <template v-slot:default>
            <h1>Search indicators</h1>
            <form v-on:submit.prevent="execute">
                <Field label="Keywords" v-slot="{id}">
                    <div class="grid grid-cols-1 grid-rows-1">
                        <Textbox autofocus class="grid-col-[1] grid-row-[1]" placeholder="Search for indicators" v-bind:id="id" v-bind:value="search" v-on:update:value="(value) => search = value"/>
                        <button class="color-middlegray cursor-pointer grid-col-[1] grid-row-[1] h-6 i-material-symbols:search-rounded justify-self-end hover-color-gray self-center w-6" type="submit"/>
                    </div>
                </Field>
            </form>
            <Table class="m-t-3" selectable v-bind:columns="3" v-bind:rows="cancelable ? null : results" v-on:select="({id}) => select(selected.includes(id) === false, id)">
                <template v-slot:header:1:ellipsis>Results</template>
                <template v-slot:header:2:ellipsis>Relevancy</template>
                <template v-slot:column:1:ellipsis="{row: {id, name, tags}}">
                    <div class="flex flex-gap-3 flex-items-center flex-wrap">
                        <div class="flex flex-gap-2 flex-items-center">
                            <Checkbox v-bind:value="selected.includes(id)" v-on:update:value="(checked) => select(checked, id)"/>
                            <span class="bold">{{name}}</span>
                        </div>
                        <div class="flex flex-basis-100% flex-gap-1 flex-items-center flex-wrap">
                            <Tag v-bind:key="index" v-bind:tag="tag" v-for="(tag, index) of tags"/>
                        </div>
                    </div>
                </template>
                <template v-slot:column:2:ellipsis="{row: {score}}">
                    <span>{{Math.round(100 * Math.max(0, Math.min(1, ((score * 2 - 1) * 2) ** 2 * 2)))}}%</span>
                </template>
                <template v-slot:column:3:ellipsis="{row: {id}}">
                    <Action icon="i-material-symbols:visibility-outline-rounded" label="View indicator" v-on:action="gotoPreview(id)"/>
                </template>
                <template v-slot:placeholder v-if="results?.length === 0">
                    <div class="flex flex-justify-center">
                        <div class="color-middlegray">
                            <template v-if="search === ''">Please enter a search keyword.</template>
                            <template v-else>
                                <span>No indicator templates found that match </span>
                                <span class="text-blue">{{search}}</span>
                                <template v-if="scope">
                                    <span> and </span>
                                    <span class="text-blue">GHG scope {{scope}}</span>
                                </template>
                                <span>.</span>
                            </template>
                        </div>
                    </div>
                </template>
            </Table>
        </template>
        <template v-slot:buttons>
            <Button role="primary" type="button" v-bind:disabled="adding || addable.length === 0" v-bind:loading="adding" v-on:click="add">
                <template v-if="addable.length > 1">Create {{addable.length}} indicators</template>
                <template v-else>Create indicator</template>
            </Button>
        </template>
    </ButtonContainer>
</template>
