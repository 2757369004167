<script setup lang="ts">
import {type Template, useGHG} from "../GHG";
import {computed, onMounted, ref} from "vue";
import Action from "../../components/Action.vue";
import Button from "../../components/Button.vue";
import ButtonContainer from "../../components/ButtonContainer.vue";
import Dropdown from "../../components/Dropdown.vue";
import Field from "../../components/Field.vue";
import Table from "../../components/Table.vue";
import Textbox from "../../components/Textbox.vue";
import Validation from "../../components/Validation.vue";
import {awaitable} from "../../components/Awaitable";
import {filterTemplates} from "../TemplateFilter";
import {useWizardProgress} from "../../Wizard";

const ghg = useGHG();
const page = "indirect";

const templates = ref<Template[]>();
onMounted(async () => templates.value = await ghg.value.templates);

const wait = ref(false);
const skip = awaitable(wait, async () => await ghg.value.create());
const next = () =>
{
    ghg.value.save(page, templates.value!, rows.value.map(({name, utility, ...rest}) => ({name: `${name} – ${utility}`, utility, ...rest})));
    skip();
};
useWizardProgress(() => ghg.value.progress(page));

const utilities = computed(() => templates.value === undefined ? [] : filterTemplates(templates.value, {}, "utility"));
const regions = (utility: string) => templates.value === undefined ? [] : filterTemplates(templates.value, {utility}, "region");
const providers = (utility: string, region: string) => templates.value === undefined ? [] : filterTemplates(templates.value, {region, utility}, "provider");

interface Row
{
    name: string;
    provider: string | null;
    region: string | null;
    utility: string | null;
}
const rows = ref<Row[]>([{name: "", provider: null, region: null, utility: null}]);

const add = () => rows.value.push({name: "", provider: null, region: null, utility: null});
const remove = (index: number) => rows.value.splice(index, 1);
</script>
<template>
    <Validation>
        <template v-slot:default="{validate}">
            <ButtonContainer>
                <template v-slot:default>
                    <h1>Select all Scope 2 Indirect Emissions</h1>
                    <p>Add all electric or gas companies your facilities use to purchase electricity or gas.</p>
                    <Table v-bind:columns="5" v-bind:loading="templates === undefined" v-bind:rows="templates === undefined ? [] : rows">
                        <template v-slot:header:1:ellipsis>Location</template>
                        <template v-slot:header:2:ellipsis>Utility</template>
                        <template v-slot:header:3:ellipsis>Region</template>
                        <template v-slot:header:4:ellipsis>Service provider</template>
                        <template v-slot:column:1="{row}">
                            <Field class="flex-grow" rule="NonEmptyString" v-bind:value="row.name" v-slot="{id}">
                                <Textbox v-bind:id="id" v-model:value="row.name" placeholder="Main office"/>
                            </Field>
                        </template>
                        <template v-slot:column:2="{row}">
                            <Field class="flex-grow" rule="NotNull" v-bind:value="row.utility" v-slot="{id}">
                                <Dropdown v-bind:id="id" v-bind:items="utilities" v-model:selected="row.utility"/>
                            </Field>
                        </template>
                        <template v-slot:column:3="{row}">
                            <Field class="flex-grow" rule="NotNull" v-bind:value="row.region" v-slot="{id}">
                                <Dropdown v-bind:id="id" v-bind:items="regions(row.utility!)" v-model:selected="row.region" v-bind:disabled="!row.utility"/>
                            </Field>
                        </template>
                        <template v-slot:column:4="{row}">
                            <Field class="flex-grow" rule="NotNull" v-bind:value="row.provider" v-slot="{id}">
                                <Dropdown v-bind:id="id" v-bind:items="providers(row.utility!, row.region!)" v-model:selected="row.provider" v-bind:disabled="!row.region || !row.utility"/>
                            </Field>
                        </template>
                        <template v-slot:column:5="{index}">
                            <div class="flex flex-gap-2 flex-justify-center w-12">
                                <Action icon="i-material-symbols:delete-outline-rounded" label="Delete" v-on:action="remove(index)" v-if="rows.length > 1"/>
                                <Action icon="i-fa6-solid:plus" label="Add" v-on:action="add()" v-if="index === rows.length - 1"/>
                            </div>
                        </template>
                    </Table>
                </template>
                <template v-slot:buttons>
                    <Button role="primary" type="button" v-bind:loading="templates === undefined || wait" v-on:click="validate(true) && next()">Generate Indicators</Button>
                    <a class="link" v-bind:aria-disabled="templates === undefined || wait" v-on:click="skip">Skip</a>
                </template>
            </ButtonContainer>
        </template>
    </Validation>
</template>
