<script setup lang="ts">
import {computed, ref, watch} from "vue";
import Button from "../../components/Button.vue";
import ButtonContainer from "../../components/ButtonContainer.vue";
import Card from "../../components/cards/SelectionCard.vue";
import {useGHG} from "../GHG";
import {useWizardProgress} from "../../Wizard";

const ghg = useGHG();
const page = "sources";

const keys = ["stationary", "mobile", "fugitive", "removal"] as const;
const createRef = (key: typeof keys[number]) => computed({get: () => ghg.value.indicators[key] !== undefined, set: (value: boolean) => ghg.value.indicators = {...ghg.value.indicators, [key]: value ? [] : undefined}});
const fugitive = createRef("fugitive");
const mobile = createRef("mobile");
const removal = createRef("removal");
const stationary = createRef("stationary");

const error = ref(false);
const next = () =>
{
    const key = keys.find((key) => ghg.value.indicators[key] !== undefined);
    if(key === undefined)
    {
        error.value = true;
    }
    else
    {
        error.value = false;
        ghg.value.next(page);
    }
};
watch([fugitive, mobile, removal, stationary], () => error.value = false);

const skip = () =>
{
    error.value = false;
    ghg.value.indicators = {indirect: []};
    ghg.value.next(page);
};

useWizardProgress(() => ghg.value.progress(page));
</script>
<template>
    <ButtonContainer>
        <template v-slot:default>
            <h1>Select all Scope 1 Direct Emissions</h1>
            <p>Direct GHG emissions from stationary sources using diesel oil, LPG, kerosene, charcoal, and towngas etc.</p>
            <div class="flex flex-gap-4 flex-justify-center flex-wrap">
                <div class="flex flex-gap-4 flex-justify-center flex-wrap">
                    <Card class="" icon="i-mdi:generator-portable" v-model:selected="stationary">
                        <template v-slot:title>Stationary sources</template>
                        <template v-slot:description>E.g. Boilers, Furnaces, ovens, and emergency electricity generator</template>
                    </Card>
                    <Card class="" icon="i-ri:truck-fill" v-model:selected="mobile">
                        <template v-slot:title>Mobile sources</template>
                        <template v-slot:description>Organization-owned vehicles used for business purposes</template>
                    </Card>
                </div>
                <div class="flex flex-gap-4 flex-justify-center flex-wrap">
                    <Card class="" icon="i-mdi:snowflake" v-model:selected="fugitive">
                        <template v-slot:title>Fugitive emission</template>
                        <template v-slot:description>Fugitive emissions from air conditioning, refrigeration systems, or fire extinguishers</template>
                    </Card>
                    <Card class="" icon="i-mdi:tree" v-model:selected="removal">
                        <template v-slot:title>GHG removal</template>
                        <template v-slot:description>Facilities with planted trees that are at least 5 meters in height</template>
                    </Card>
                </div>
            </div>
            <div class="flex flex-justify-center m-t-3">
                <div class="color-pink" v-if="error">Select an indicator or skip.</div>
            </div>
        </template>
        <template v-slot:buttons>
            <Button role="primary" type="button" v-on:click="next">Continue</Button>
            <a class="link" v-on:click="skip">Skip</a>
        </template>
    </ButtonContainer>
</template>
