<script lang="ts" setup>
import type {IndicatorFormula, IndicatorFormulaParameters} from "../../API";
import {computed, watch} from "vue";
import {execute, useFormulaRunner} from "../../components/FormulaRunner";
import {extractExpressionUnit, extractFunctionIndices, extractFunctionParameters} from "../../../../backend/main/SymbolicMath";
import AsyncValidation from "../../components/AsyncValidation.vue";
import Field from "../../components/Field.vue";
import FormulaParameter from "./FormulaParameter.vue";
import Tex from "../../components/Tex.vue";
import Textbox from "../../components/Textbox.vue";
import {validator} from "../../components/FormulaValidator";

interface Props
{
    disabled: boolean;
    readonly: boolean;
}
withDefaults(defineProps<Props>(), {});
const formula = defineModel<IndicatorFormula>("formula", {required: true});

const getExpression = () => formula.value.expression ?? "";
const setExpression = async (expression: string) =>
{
    formula.value = {...formula.value, expression};
};
const expression = computed({get: getExpression, set: setExpression});

const names = computed(() =>
{
    try
    {
        return extractFunctionParameters([expression.value]);
    }
    catch(error)
    {
        return new Set<string>();
    }
});
const indices = computed(() =>
{
    try
    {
        const indices = extractFunctionIndices([expression.value]);
        return Object.fromEntries(indices.flatMap(({indices, keys: [value]}) => Array.from(indices).map((name) => [name, value])));
    }
    catch(error)
    {
        return {};
    }
});
const params = computed(() =>
{
    try
    {
        const exclude = Object.keys(indices.value);
        return new Set(Array.from(names.value).filter((name) => exclude.includes(name) === false));
    }
    catch(error)
    {
        return new Set<string>();
    }
});

const getParameters = () => Object.fromEntries(Object.entries(formula.value.parameters).filter(([name]) => params.value.has(name)));
const setParameters = async (parameters: IndicatorFormulaParameters) =>
{
    formula.value = {...formula.value, parameters};
};
const parameters = computed({get: getParameters, set: setParameters});

const variables = computed(() =>
{
    const a = Object.entries(parameters.value).map<[string, {unit: string; value: number}]>(([name, {unit}]) => [name, {unit, value: 1}]);
    const b = Object.entries(indices.value).map<[string, {value: string}]>(([name, value]) => [name, {value}]);
    return {...Object.fromEntries(a), ...Object.fromEntries(b)};
});
const {error, result} = useFormulaRunner(expression, variables);
const mismatched = computed(() =>
{
    if(error.value === undefined)
    {
        return null;
    }
    else
    {
        const match = error.value.match(/^Units do not match \('(.+?)' != '(.+?)'\)$/);
        if(match)
        {
            const [_, a, b] = match;
            const [p, q] = extractExpressionUnit([a, b]);
            return `${q} != ${p}`;
        }
        else
        {
            return null;
        }
    }
});
const formulaValidator = async (expression: string) =>
{
    const errors = await validator(expression);
    if(errors.length === 0)
    {
        try
        {
            await execute(expression, variables.value);
        }
        catch(error)
        {
            return [`${error instanceof Error ? error.message : error}`];
        }
        return [];
    }
    else
    {
        return errors;
    }
};
watch(result, async (result) =>
{
    if(result !== undefined)
    {
        const {unit: existing} = formula.value;
        const [unit] = extractExpressionUnit([result]);
        if(existing !== unit)
        {
            formula.value = {...formula.value, unit};
        }
    }
});
</script>
<template>
    <AsyncValidation v-bind:validator="formulaValidator" v-bind:value="expression" v-slot="{error: asyncError}">
        <Field label="Expression" rule="NonEmptyString" v-bind:class="{invalid: asyncError}" v-bind:value="expression">
            <template v-slot:default="{id}">
                <Textbox v-bind:disabled="disabled" v-bind:id="id" v-bind:readonly="readonly" v-model:value="expression"/>
            </template>
            <template v-slot:error="{error}">
                <template v-if="asyncError === 'FunctionExpected'">Expression must be a function, e.g. <span class="mono">f(x) = x * 2</span></template>
                <template v-else-if="asyncError !== null && /^InvalidExpression: /.test(asyncError)">{{asyncError.replace(/^InvalidExpression: /, "")}}</template>
                <template v-else-if="error">{{error}}</template>
                <div class="color-pink flex flex-items-center flex-gap-2" v-else-if="mismatched">
                    <span>Units do not match:</span>
                    <Tex type="expression" v-bind:expression="mismatched" v-if="mismatched"/>
                </div>
                <template v-else>{{asyncError}}</template>
            </template>
        </Field>
    </AsyncValidation>
    <FormulaParameter v-bind:disabled="disabled" v-bind:names="params" v-bind:readonly="readonly" v-model:parameters="parameters"/>
    <Field label="Example calculation">
        <div class="grid-lines bg-verylightgray b-rd-2 flex flex-col flex-gap-4 flex-justify-between m-t-1 min-h-2em p-3">
            <Tex type="expression" v-bind:expression="expression"/>
            <template v-bind:key="index" v-for="(name, index) of names">
                <Tex type="expression" v-bind:expression="`${name} = 1 ${parameters[name].unit}`" v-if="parameters[name]"/>
                <Tex type="expression" v-bind:expression="`${name} = ${indices[name]}`" v-if="indices[name]"/>
            </template>
            <Tex type="expression" v-bind:expression="result" v-if="result"/>
            <div class="text-pink flex flex-items-center flex-gap-2" v-else-if="mismatched">
                <span>Units do not match:</span>
                <Tex type="expression" v-bind:expression="mismatched" v-if="mismatched"/>
            </div>
            <div class="text-pink" v-else>{{error}}</div>
        </div>
    </Field>
</template>