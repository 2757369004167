import Add from "./Add.vue";
import Indicator from "./Indicator.vue";
import Indicators from "./Indicators.vue";
import Preview from "./Preview.vue";
import type {RouteRecordRaw} from "vue-router";
import Search from "./Search.vue";
import SearchSidebar from "./SearchSidebar.vue";
import {id} from "../RouteId";

export const IndicatorRoute: RouteRecordRaw =
{
    children:
    [
        {
            component: Indicators,
            path: "",
            props: ({params: {projectId}}) => ({projectId})
        },
        {
            component: Indicator,
            meta:
            {
                breadcrumb: () => ""
            },
            path: `${id("indicatorId")}/`,
            props: ({params: {indicatorId, projectId}}) => ({add: false, indicatorId, projectId})
        },
        {
            children:
            [
                {
                    component: Add,
                    meta:
                    {
                        home: ({params: {projectId}}) => `/project/${projectId}/indicator/`,
                        layout: "wizard"
                    },
                    path: "",
                    props: ({params: {projectId}}) => ({projectId})
                },
                {
                    children:
                    [
                        {
                            components:
                            {
                                default: Search,
                                sidebar: SearchSidebar
                            },
                            meta:
                            {
                                home: ({params: {projectId}}) => `/project/${projectId}/indicator/`,
                                layout: "wizard",
                                scrollback: true
                            },
                            name: "Search",
                            path: "",
                            props:
                            {
                                default: ({params: {projectId}, query: {scope, "select[]": templateIds}}) => ({projectId, scope, templateIds}),
                                sidebar: ({params: {projectId}, query: {scope, "select[]": templateIds}}) => ({projectId, scope, templateIds})
                            }
                        },
                        {
                            component: Preview,
                            meta:
                            {
                                breadcrumb: () => "Preview",
                                home: ({params: {projectId}}) => `/project/${projectId}/indicator/`,
                                layout: "wizard"
                            },
                            path: `${id("templateId")}/`,
                            props: ({params: {projectId, templateId}, query: {"select[]": templateIds}}) => ({projectId, templateId, templateIds})
                        }
                    ],
                    meta:
                    {
                        breadcrumb: () => "Search",
                        scrollback: true
                    },
                    path: "search/",
                    props: () => ({keepAlive: true})
                },
                {
                    component: Indicator,
                    meta:
                    {
                        breadcrumb: () => "",
                        home: ({params: {projectId}}) => `/project/${projectId}/indicator/add/`,
                        layout: "wizard"
                    },
                    path: `${id("indicatorId")}/`,
                    props: ({params: {indicatorId, projectId}}) => ({add: true, indicatorId, projectId})
                }
            ],
            meta:
            {
                breadcrumb: () => "Add indicator"
            },
            path: "add/"
        }
    ],
    meta:
    {
        breadcrumb: () =>  "Indicators"
    },
    path: "indicator/"
};