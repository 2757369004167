<script lang="ts" setup>
import ReportCard from "./ReportCard.vue";
import ReportCardItem from "./ReportCardItem.vue";
import {ReportMode} from "../ReportMode";
import Textarea from "../../components/Textarea.vue";

interface Emits
{
    (event: "remove"): void;
}
interface Props
{
    mode?: ReportMode;
}
const emit = defineEmits<Emits>();
withDefaults(defineProps<Props>(),
{
    mode: ReportMode.View
});
const value = defineModel<string>("value", {default: ""});

const remove = () => emit("remove");
</script>
<template>
    <ReportCard v-bind:mode="mode" v-bind:value="value">
        <ReportCardItem v-bind:mode="mode" v-on:remove="remove">
            <template v-slot:edit>
                <Textarea class="p-3" placeholder="Paragraph" v-model:value="value"/>
            </template>
            <template v-slot:preview>
                <div class="overflow-x-hidden p-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut pretium augue arcu, et dapibus magna elementum nec.</div>
            </template>
            <template v-slot:view>
                <div class="overflow-wrap-anywhere page-break-inside-avoid white-space-pre-wrap">{{value}}&ZeroWidthSpace;</div>
            </template>
        </ReportCardItem>
    </ReportCard>
</template>