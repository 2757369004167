<script lang="ts" setup>
import {Frequencies, Types} from "./Indicator.vue";
import Button from "../components/Button.vue";
import ButtonContainer from "../components/ButtonContainer.vue";
import Labeled from "../components/Labeled.vue";
import Loader from "../components/Loader.vue";
import SDGs from "./SDGs.json";
import Tags from "../components/Tags.vue";
import Tex from "../components/Tex.vue";
import {typeSafeObjectFromEntries} from "../components/TypeSafe";
import {useBreadcrumb} from "../Breadcrumb";
import {useRouter} from "vue-router";
import {useSWR} from "../SWR";

interface Props
{
    projectId: string;
    templateId: string;
    templateIds?: string[];
}
const props = withDefaults(defineProps<Props>(), {templateIds: () => []});

const {data: template} = useSWR("/template/{templateId}/", {templateId: props.templateId}, {});

const router = useRouter();
const select = async () =>
{
    const {templateId, templateIds} = props;
    const select = [...templateIds, ...templateIds.includes(templateId) ? [] : [templateId]];
    const query = select.map((id) => `select[]=${id}`).join("&");
    await router.replace(`/project/${props.projectId}/indicator/add/search/?${query}`);
};
useBreadcrumb({templateId: () => template.value?.name});
</script>
<template>
    <Loader v-bind:loading="template === undefined">
        <ButtonContainer>
            <template v-slot:default>
                <h1>View indicator</h1>
                <div class="flex flex-col flex-gap-7">
                    <Labeled label="Indicator name">{{template?.name}}</Labeled>
                    <Labeled label="Indicator description">{{template?.description}}</Labeled>
                    <Labeled label="Tags">
                        <Tags v-bind:tags="template?.tags ?? []"/>
                    </Labeled>
                    <Labeled label="Related SDGs">
                        <Tags v-bind:tags="(template?.sdgs ?? []).map((sdg) => `SDG ${sdg}: ${SDGs[sdg]}`)"/>
                    </Labeled>
                    <Labeled label="Frequency of measurement">{{typeSafeObjectFromEntries(Frequencies)[template?.frequency!]}}</Labeled>
                    <Labeled label="Measurement type">{{typeSafeObjectFromEntries(Types)[template?.type!]}}</Labeled>
                    <Labeled label="Options" v-if="template?.type === 'multiple-choice' || template?.type === 'single-choice'">
                        <ul>
                            <li v-bind:key="index" v-for="(option, index) of template?.options">{{option}}</li>
                        </ul>
                    </Labeled>
                    <Labeled label="Currency" v-if="template?.type === 'monetary-value'">{{template?.currency}}</Labeled>
                    <Labeled label="Unit" v-if="template?.type === 'unit'">
                        <Tex type="unit" v-bind:expression="template?.unit"/>
                    </Labeled>
                    <Labeled label="Formula" v-if="template?.type === 'formula'">
                        <Tex type="expression" v-bind:expression="template?.formula?.expression"/>
                    </Labeled>
                    <template v-bind:key="index" v-for="([key, {name, unit}], index) of Object.entries(template?.formula?.parameters ?? {})">
                        <div>
                            <span class="font-bold m-r-1 text-small">Parameter</span>
                            <Tex type="variable" v-bind:expression="key"/>
                        </div>
                        <div>
                            <span class="m-r-1">{{name}}</span>
                            <span v-if="unit === ''">(count)</span>
                            <template v-else>
                                <span class="color-middlegray m-r-1">in</span>
                                <Tex type="unit" v-bind:expression="unit"/>
                            </template>
                        </div>
                    </template>
                </div>
            </template>
            <template v-slot:buttons>
                <Button role="primary" type="button" v-on:click="select">Select</Button>
            </template>
        </ButtonContainer>
    </Loader>
</template>