<script lang="ts" setup>
import {ref, useTemplateRef} from "vue";
import ReportCard from "./ReportCard.vue";
import ReportCardItem from "./ReportCardItem.vue";
import {ReportMode} from "../ReportMode";
import imageCompression from "browser-image-compression";

interface Emits
{
    (event: "remove"): void;
}
interface Props
{
    mode?: ReportMode;
}
const emit = defineEmits<Emits>();
withDefaults(defineProps<Props>(),
{
    mode: ReportMode.View
});
const value = defineModel<[string | null, string | null]>("value", {default: [null, null]});

const inputs = useTemplateRef<HTMLInputElement[]>("inputs");
const add = (index: number) => inputs.value![index]!.click();

const uploading = ref<[boolean, boolean, boolean]>([false, false, false]);
const upload = async (index: number) =>
{
    const file = inputs.value![index].files?.[0];
    inputs.value![index].value = "";
    if(file)
    {
        uploading.value[index] = true;
        try
        {
            const {type} = file;
            const fileType = ["image/jpeg", "image/png", "image/webp"].includes(type) ? type : "image/webp";
            const options =
            {
                fileType,
                maxIteration: 32,
                maxSizeMB: 1,
                maxWidthOrHeight: 1920
            };
            const resized = await imageCompression(file, options);
            const reader = new FileReader();
            reader.addEventListener("load", (e) =>
            {
                const base64 = e.target?.result as string;
                value.value = [index === 0 ? base64 : value.value[0], index === 1 ? base64 : value.value[1]];
            });
            reader.readAsDataURL(resized);
        }
        finally
        {
            uploading.value[index] = false;
        }
    }
};

const remove = (index: number) =>
{
    if(value.value.every((v) => v === null))
    {
        emit("remove");
    }
    else
    {
        value.value = [index === 0 ? null : value.value[0], index === 1 ? null : value.value[1]];
    }
};
</script>
<template>
    <ReportCard v-bind:mode="mode" v-bind:value="value">
        <template v-slot:edit>
            <ReportCardItem v-bind:index="index" v-bind:key="index" v-bind:mode="mode" v-bind:value="value" v-for="(image, index) of value" v-on:add="add(index)" v-on:remove="remove(index)">
                <div class="color-lightgray cursor-pointer h-100% hover-color-gray flex flex-items-center flex-justify-center" v-on:click="add(index)" v-if="image != null || uploading[index]">
                    <div class="i-svg-spinners:180-ring-with-bg text-6" v-if="image === null || uploading[index]"/>
                    <img class="max-w-100%" v-bind:src="image" v-else/>
                </div>
                <template v-slot:add>Add image card</template>
                <input accept="image/*" class="absolute h-0 w-0" ref="inputs" type="file" v-on:change="upload(index)"/>
            </ReportCardItem>
        </template>
        <template v-slot:preview>
            <ReportCardItem v-bind:mode="mode">
                <div class="flex flex-self-center p-3">
                    <div class="color-lightgray i-fa6-solid-image text-16"/>
                </div>
            </ReportCardItem>
        </template>
        <template v-slot:view>
            <div class="grid grid-cols-[1fr_1fr] grid-gap-4 grid-justify-items-center page-break-inside-avoid">
                <template v-for="url of value">
                    <div v-if="url === null"/>
                    <img class="b-rd-2 max-w-100% print-max-h-100vh print-max-w-50vw" v-bind:src="url" v-else/>
                </template>
            </div>
        </template>
    </ReportCard>
</template>