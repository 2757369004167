<script lang="ts" setup>
import {onMounted, ref, useAttrs, useTemplateRef, watch} from "vue";

type Props =
{
    closable?: boolean;
};
withDefaults(defineProps<Props>(), {closable: false});
const visible = defineModel<boolean>("visible", {default: false});

const close = () => visible.value = false;
defineExpose({close});

const attrs = useAttrs();
defineOptions({inheritAttrs: false});

const container = useTemplateRef("container");
const shown = ref(false);
const show = () =>
{
    const delayed = () =>
    {
        shown.value = visible.value;
        if(visible.value)
        {
            setTimeout(() => container.value!.scrollTo(0, 0), 50);
        }
    };
    setTimeout(() => delayed(), 50);
};
watch(visible, () => show(), {flush: "post"});
onMounted(() => show());
</script>
<template>
    <Teleport to="body">
        <Transition enterActiveClass="transition-opacity" enterFromClass="opacity-0" enterToClass="opacity-100" leaveActiveClass="transition-opacity" leaveFromClass="opacity-100" leaveToClass="opacity-0">
            <div class="fixed bg-black bg-opacity-50 h-100% left-0 top-0 w-100% z-1" v-if="shown"/>
        </Transition>
        <div class="fixed grid grid-items-center grid-justify-center h-100vh left-0 overflow-y-auto top-0 pointer-events-none w-100vw z-2" ref="container">
            <Transition enterActiveClass="transition-opacity,transform" enterFromClass="opacity-0 transform-scale-50" enterToClass="opacity-100 transform-scale-100" leaveActiveClass="transition-opacity,transform" leaveFromClass="opacity-100 transform-scale-100" leaveToClass="opacity-0 transform-scale-50">
                <dialog aria-modal="true" class="b-none bg-white b-rounded-4 min-w-[min(50em,calc(100vw-4em))] p-0 pointer-events-auto relative" open v-bind="attrs" v-if="shown">
                    <div class="absolute color-gray cursor-pointer hover:color-black i-fa6-solid:xmark right-4 top-4 z-2" v-if="closable" v-on:click="close"/>
                    <div class="m-9">
                        <slot v-bind:close="close"/>
                    </div>
                </dialog>
            </Transition>
        </div>
    </Teleport>
</template>