import {FunctionAssignmentNode, parse} from "mathjs";
import {createWorkerModule} from "./WorkerModule";

function validate(expression: string)
{
    try
    {
        const node = parse(expression);
        if(node instanceof FunctionAssignmentNode)
        {
            return null;
        }
        else
        {
            return "FunctionExpected" as const;
        }
    }
    catch(error)
    {
        return `InvalidExpression: ${error instanceof Error ? error.message : error}` as const;
    }
}

export type FormulaValidatorWorkerModule =
{
    validate: typeof validate;
}
createWorkerModule<FormulaValidatorWorkerModule>({validate});