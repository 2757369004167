<script setup lang="ts">
import {type Template, useGHG} from "../GHG";
import {onMounted, ref} from "vue";
import Action from "../../components/Action.vue";
import Button from "../../components/Button.vue";
import ButtonContainer from "../../components/ButtonContainer.vue";
import Field from "../../components/Field.vue";
import Table from "../../components/Table.vue";
import Textbox from "../../components/Textbox.vue";
import Validation from "../../components/Validation.vue";
import {useWizardProgress} from "../../Wizard";

const ghg = useGHG();
const page = "removal";

const templates = ref<Template[]>();
onMounted(async () => templates.value = await ghg.value.templates);

const skip = () => ghg.value.next(page);
const next = () =>
{
    ghg.value.save(page, templates.value!, rows.value.map(({name}) => ({name, removal: "tree"})));
    skip();
};
useWizardProgress(() => ghg.value.progress(page));

interface Row
{
    name: string;
}
const rows = ref<Row[]>([{name: ""}]);

const add = () => rows.value.push({name: ""});
const remove = (index: number) => rows.value.splice(index, 1);
</script>
<template>
    <Validation>
        <template v-slot:default="{validate}">
            <ButtonContainer>
                <template v-slot:default>
                    <h1>Add newly planted trees</h1>
                    <p>Add facilities with planted trees that are at least 5 meters in height.</p>
                    <Table v-bind:columns="2" v-bind:loading="templates === undefined" v-bind:rows="templates === undefined ? [] : rows">
                        <template v-slot:header:1:ellipsis>Plot location</template>
                        <template v-slot:column:1="{row}">
                            <Field class="flex-grow" rule="NonEmptyString" v-bind:value="row.name" v-slot="{id}">
                                <Textbox v-bind:id="id" v-model:value="row.name" placeholder="Headquarters Park"/>
                            </Field>
                        </template>
                        <template v-slot:column:2="{index}">
                            <div class="flex flex-gap-2 flex-justify-center w-12">
                                <Action icon="i-material-symbols:delete-outline-rounded" label="Delete" v-on:action="remove(index)" v-if="rows.length > 1"/>
                                <Action icon="i-fa6-solid:plus" label="Add" v-on:action="add()" v-if="index === rows.length - 1"/>
                            </div>
                        </template>
                    </Table>
                </template>
                <template v-slot:buttons>
                    <Button role="primary" type="button" v-bind:disabled="templates === undefined" v-on:click="validate(true) && next()">Continue</Button>
                    <a class="link" v-on:click="skip">Skip</a>
                </template>
            </ButtonContainer>
        </template>
    </Validation>
</template>
