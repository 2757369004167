<script generic="Dummy" lang="ts" setup>

interface Props
{
}
interface Slots
{
    default: (_: {}) => any;
    tooltip: (_: {}) => any;
};
defineProps<Props>();
const slots = defineSlots<Slots>();
</script>
<template>
    <div class="cursor-pointer parent" v-if="slots['tooltip']">
        <slot name="default"/>
        <div class="flex-justify-items-center flex-basis-100% hidden parent-hover-hover-hidden parent-hover-flex relative left-50% z-1">
            <div class="absolute top-0 drop-shadow grid grid-cols-[max-content] grid-rows-[max-content_max-content] grid-justify-items-center transform-translate-x-[-50%]">
                <div class="color-white triangle-1 rotate-180"/>
                <div class="bg-white b-rd-2 p-3">
                    <slot name="tooltip"/>
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        <slot/>
    </div>
</template>