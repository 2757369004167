<script lang="ts" setup>
import ReportCard from "./ReportCard.vue";
import ReportCardItem from "./ReportCardItem.vue";
import {ReportMode} from "../ReportMode";

interface Emits
{
    (event: "remove"): void;
}
interface Props
{
    mode?: ReportMode;
    placeholder?: string;
    size?: number;
}
const emit = defineEmits<Emits>();
withDefaults(defineProps<Props>(),
{
    mode: ReportMode.View,
    placeholder: "Section Title",
    size: 6
});
const value = defineModel<string>("value", {default: ""});
const remove = () => emit("remove");
</script>
<style scoped>
</style>
<template>
    <ReportCard v-bind:mode="mode" v-bind:value="value">
        <ReportCardItem v-bind:mode="mode" v-on:remove="remove">
            <div class="page-break-after-avoid page-break-inside-avoid" v-bind:style="{'font-size': `${size * 0.25}rem`}">
                <input class="b-none bg-transparent box-border font-sans font-size-inherit outline-none p-3 w-100%" type="text" v-bind:placeholder="placeholder" v-if="mode === ReportMode.Edit" v-model="value"/>
                <div class="p-3 text-6" v-else-if="mode === ReportMode.Preview">{{placeholder}}</div>
                <div class="font-size-inherit overflow-wrap-anywhere" v-else>{{value}}&ZeroWidthSpace;</div>
            </div>
        </ReportCardItem>
    </ReportCard>
</template>
