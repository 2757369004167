import {type MaybeRefOrGetter, onActivated, ref, toValue, watchEffect} from "vue";

export const progress = ref<number | undefined>();

export function useWizardBreadcrumb()
{
    useWizardProgress();
}

export function useWizardProgress(value?: MaybeRefOrGetter<number>)
{
    onActivated(() => progress.value = toValue(value));
    watchEffect(() => progress.value = toValue(value));
}