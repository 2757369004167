<script lang="ts" setup>
import PopupMenu, {type MenuItem} from "../PopupMenu.vue";
import BasicCard from "./BasicCard.vue";
import {useSlots} from "vue";

interface Props
{
    disabled?: boolean;
    loading?: boolean;
    menu?: MenuItem[];
}
withDefaults(defineProps<Props>(), {disabled: false, menu: () => []});
const slots = useSlots();
</script>
<template>
    <BasicCard v-bind:loading="loading">
        <template v-slot:header v-if="slots.header">
            <slot name="header"/>
        </template>
        <template v-slot:default>
            <div class="text-center">
                <slot/>
            </div>
            <PopupMenu class="absolute bottom-2 right-2" icon="i-fa6-solid:ellipsis" v-bind:disabled="disabled" v-bind:menu="menu"/>
        </template>
    </BasicCard>
</template>