<script lang="ts" setup>
interface Props
{
    disabled?: boolean;
}
withDefaults(defineProps<Props>(),
{
    disabled: false
});
const value = defineModel<string>("value", {default: ""});
</script>
<template>
    <div>
        <div class="box-border relative h-100% p-3 p-l-9 w-100%" style="word-break: break-word" v-bind:class="[disabled ? 'color-middlegray' : '']">
            <ul class="box-border font-sans m-0 p-0 text-body w-100% white-space-pre-wrap">
                <li v-for="line of value.split('\n')">
                    <span class="text-transparent" style="white-space: pre-wrap">{{line}}&ZeroWidthSpace;</span>
                </li>
            </ul>
            <textarea class="absolute b-none bg-transparent box-border font-sans h-100% left-0 min-h-1lh p-3 p-l-9 outline-none resize-none text-body top-0 w-100%" v-bind:disabled="disabled" v-bind:value="value" v-on:input="(e) => value = (e.target as HTMLInputElement).value" />
        </div>
    </div>
</template>