<script lang="ts">
import {computed, ref} from "vue";
import Tooltip from "./Tooltip.vue";
import {awaitableSelection} from "./Awaitable";

export interface ToolbarButton
{
    action: () => Promise<void> | void;
    disabled?: boolean;
    icon: `i-${string}:${string}`;
    label?: string;
    visible?: boolean;
    tooltip?: string;
    wait?: boolean;
}
</script>
<script lang="ts" setup>
interface Props
{
    buttons?: ToolbarButton[][];
}
const props = withDefaults(defineProps<Props>(), {buttons: () => []});

const sections = computed(() => props.buttons.map((v, section) => v.map((rest, index) => ({...rest, id: `${section}-${index}`}))));
const executing = ref(new Set<string>());
const execute = awaitableSelection(executing, async (selected: string) =>
{
    const [{action}] = sections.value.flat().filter(({id}) => id === selected);
    await action();
});
</script>
<template>
    <div class="flex flex-gap-4 flex-wrap text-6" style="grid-auto-columns: min-content">
        <div class="auto-flow-col grid" v-bind:key="index" v-for="(section, index) of sections">
            <TransitionGroup enter-active-class="transition" enter-from-class="opacity-0" enter-to-class="opacity-100" leave-active-class="transition" appear-from-class="opacity-100" leave-to-class="opacity-0">
                <template v-bind:key="id" v-for="({disabled, icon, id, label, tooltip, visible, wait}) of section">
                    <Tooltip class="aria-busy-color-green b-1 b-solid not-first-m-l-[-1px] flex flex-items-center flex-content-center flex-justify-center flex-wrap first-b-rd-l-2 hover-z-1 last-b-rd-r-2 p-x-2 p-y-1 link" v-bind:aria-busy="executing.has(id) || wait" v-bind:aria-disabled="disabled || executing.has(id) || wait" v-bind:aria-label="label" v-bind:key="id" v-on:click="execute(id)" v-if="visible ?? true">
                        <template v-slot:default>
                            <span class="i-svg-spinners:180-ring-with-bg m-r-1 text-6" v-if="executing.has(id) || wait"/>
                            <span v-bind:class="icon" v-else/>
                            <span class="m-l-1 text-small white-space-nowrap" v-if="label">{{label}}</span>
                        </template>
                        <template v-slot:tooltip v-if="tooltip">
                            <span class="color-black text-body">{{tooltip}}</span>
                        </template>
                    </Tooltip>
                </template>
            </TransitionGroup>
        </div>
    </div>
</template>./Awaitable