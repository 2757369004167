<script setup lang="ts">
import {computed, onActivated, ref} from "vue";
import Dropdown from "../components/Dropdown.vue";
import Field from "../components/Field.vue";
import {useRouter} from "vue-router";
import {useSearchURL} from "./Search.vue";

interface Props
{
    projectId: string;
    scope?: string;
    templateIds?: string[];
}
const props = defineProps<Props>();
const router = useRouter();

const Scopes: [1 | 2 | 3 | null, string][] =
[
    [null, "All"],
    [1, "Scope 1"],
    [2, "Scope 2"],
    [3, "Scope 3"]
];
const scope = ref<number | null>(props.scope === undefined ? null : Number(props.scope));
onActivated(() => scope.value = props.scope === undefined ? null : Number(props.scope));

useSearchURL(computed(() => props.projectId), computed(() => scope.value === null ? undefined : String(scope.value)), computed(() => props.templateIds));
</script>
<template>
    <h1>&ZeroWidthSpace;</h1>
    <Field label="GHG scope" v-slot="{id}">
        <Dropdown v-bind:id="id" v-bind:items="Scopes" v-model:selected="scope"/>
    </Field>
</template>
