<script lang="ts" setup>
import {Frequencies, Types} from "./Indicator.vue";
import Toolbar, {type ToolbarButton} from "../components/Toolbar.vue";
import {computed, ref} from "vue";
import Checkbox from "../components/Checkbox.vue";
import ModalQuestion from "../components/ModalQuestion.vue";
import PopupMenu from "../components/PopupMenu.vue";
import Table from "../components/Table.vue";
import Tags from "../components/Tags.vue";
import Tex from "../components/Tex.vue";
import {authorization} from "../Authorization";
import {awaitableSelection} from "../components/Awaitable";
import {fetch} from "../API";
import {invokable} from "../components/Invokable";
import {typeSafeObjectFromEntries} from "../components/TypeSafe";
import {useBreadcrumb} from "../Breadcrumb";
import {useRouter} from "vue-router";
import {useSWR} from "../SWR";

interface Props
{
    projectId: string;
}
const props = withDefaults(defineProps<Props>(), {});

const {data: indicators, mutate} = useSWR("/project/{projectId}/indicator/", {projectId: props.projectId}, {fields: ["id", "formula", "frequency", "name", "measurements", "tags", "type"]});
const router = useRouter();

const gotoAddIndicator = async () =>
{
    await router.push(`/project/${props.projectId}/indicator/add/`);
};
const gotoEdit = async (indicatorId: string) =>
{
    const {projectId} = props;
    await router.push(`/project/${projectId}/indicator/${indicatorId}/`);
};

const selected = ref<string[]>([]);
const select = (checked: boolean, indicatorId: string) =>
{
    if(checked)
    {
        selected.value.push(indicatorId);
    }
    else
    {
        selected.value = selected.value.filter((id) => id !== indicatorId);
    }
};

const removing = ref(new Set<string>());
const remove = ref<(() => Promise<void>) | null>(null);
const removeIndicators = invokable(remove, awaitableSelection(removing, async (indicatorIds: string[]) =>
{
    const {projectId} = props;
    const promises = indicatorIds.map((indicatorId) => fetch("delete", "/project/{projectId}/indicator/{indicatorId}/", {indicatorId, projectId}, {}, null));
    await Promise.all(promises);
    indicators.value = indicators.value!.filter(({id}) => indicatorIds.includes(id) === false);
    selected.value = selected.value.filter((id) => indicatorIds.includes(id) === false);
    await mutate();
}));

const cloning = ref(new Set<string>());
const cloneIndicators = awaitableSelection(cloning, async (indicatorIds: string[]) =>
{
    const {projectId} = props;
    await fetch("post", "/project/{projectId}/indicator/clone/", {projectId}, {}, indicatorIds);
    indicators.value = await fetch("get", "/project/{projectId}/indicator/", {projectId}, {}, null);
    await mutate(indicators.value);
});

const creating = ref(new Set<string>());
const createTemplate = awaitableSelection(creating, async (indicatorIds: string[]) =>
{
    const {projectId} = props;
    await fetch("post", "/project/{projectId}/indicator/template/create/", {projectId}, {}, indicatorIds);
});

const menu = (indicatorId: string) =>
{
    const administrator = authorization.value?.roles.includes("administrator");
    const createTemplateButton =
    {
        action: () => createTemplate([indicatorId]),
        text: "Save indicator as template"
    };
    const optional = administrator ? [createTemplateButton] : [];
    const menu =
    [
        {
            action: () => cloneIndicators([indicatorId]),
            text: "Clone indicator"
        },
        ...optional,
        {
            action: () => removeIndicators([indicatorId]),
            text: "Remove indicator"
        }
    ];
    return menu;
};

const toolbar = computed(() =>
{
    const administrator = authorization.value?.roles.includes("administrator");
    const createTemplateButton: ToolbarButton =
    {
        action: () => createTemplate([...selected.value]),
        icon: "i-tabler:copy-plus-filled" as const,
        tooltip: selected.value.length === 1 ? "Save indicator as template" : "Save indicators as template",
        visible: selected.value.length > 0,
        wait: creating.value.size > 0
    };
    const optional = administrator ? [createTemplateButton] : [];
    const buttons: ToolbarButton[][] =
    [
        [
            {
                action: gotoAddIndicator,
                icon: "i-material-symbols:add",
                label: "Add indicator"
            }
        ],
        [
            {
                action: () =>
                {
                    selected.value = [];
                },
                icon: "i-material-symbols:close",
                label: `${selected.value.length} selected`,
                visible: selected.value.length > 0,
                wait: cloning.value.size > 0
            },
            {
                action: () => cloneIndicators([...selected.value]),
                icon: "i-tabler:copy-plus",
                tooltip: selected.value.length === 1 ? "Clone indicator" : "Clone indicators",
                visible: selected.value.length > 0,
                wait: cloning.value.size > 0
            },
            ...optional,
            {
                action: () => removeIndicators([...selected.value]),
                icon: "i-material-symbols:delete-outline-rounded",
                tooltip: selected.value.length === 1 ? "Remove indicator" : "Remove indicators",
                visible: selected.value.length > 0,
                wait: removing.value.size > 0
            }
        ]
    ];
    return buttons;
});

useBreadcrumb();
</script>
<template>
    <h1>Manage indicators</h1>
    <p>Add and manage both standardized and custom indicators to effectively measure sustainability and impact.</p>
    <Toolbar class="m-t-6" v-bind:buttons="toolbar"/>
    <Table class="m-t-3" selectable v-bind:columns="5" v-bind:proportions="[3, 1, 1, 1]" v-bind:rows="indicators" v-on:select="({id}) => gotoEdit(id)">
        <template v-slot:header:1:ellipsis>Indicator</template>
        <template v-slot:header:2:ellipsis>Frequency</template>
        <template v-slot:header:3:ellipsis>Type</template>
        <template v-slot:header:4:ellipsis>Entries</template>
        <template v-slot:column:1:ellipsis="{row: {id, name, tags}}">
            <div class="flex flex-gap-3 flex-items-center">
                <Checkbox class="hidden sm-flex" v-bind:disabled="cloning.has(id) || creating.has(id) || removing.has(id)" v-bind:value="selected.includes(id)" v-on:update:value="(checked) => select(checked, id)"/>
                <span class="ellipsis font-bold v-middle">{{name}}</span>
            </div>
            <Tags class="m-t-3" v-bind:limit="4" v-bind:tags="tags ?? []" v-if="tags?.length ?? 0"/>
        </template>
        <template v-slot:column:2:ellipsis="{row: {frequency}}">
            <span>{{typeSafeObjectFromEntries(Frequencies)[frequency]}}</span>
        </template>
        <template v-slot:column:3:ellipsis="{row: {type, unit, units}}">
            <template v-if="type === 'formula' || type === 'unit'">
                <template v-if="type === 'formula'">
                    <template v-bind:key="index" v-for="(unit, index) of units">
                        <span v-if="unit === ''">count</span>
                        <Tex type="unit" v-bind:expression="unit" v-else/>
                        <span class="white-space-pre-wrap" v-if="index < units.length - 1">, </span>
                    </template>
                    <span class="text-middlegray p-x-1">→</span>
                </template>
                <span v-if="unit === ''">count</span>
                <Tex type="unit" v-bind:expression="unit" v-else/>
            </template>
            <span v-else>{{typeSafeObjectFromEntries(Types)[type]}}</span>
        </template>
        <template v-slot:column:4:ellipsis="{row: {measurements}}">
            <template v-if="measurements === 0">No entries</template>
            <template v-else-if="measurements === 1">1 entry</template>
            <template v-else>{{measurements}} entries</template>
        </template>
        <template v-slot:column:5:ellipsis="{row: {id}}">
            <div class="flex-gap-2 flex-justify-center flex-items-center flex-grow flex-row">
                <a aria-busy="true" class="color-green i-svg-spinners:180-ring-with-bg text-6" v-if="cloning.has(id) || creating.has(id) || removing.has(id)"/>
                <template v-else>
                    <PopupMenu icon="i-fa6-solid:ellipsis-vertical" v-bind:menu="menu(id)"/>
                </template>
            </div>
        </template>
        <template v-slot:placeholder v-if="indicators?.length === 0">
            <div class="flex flex-justify-center">
                <div class="color-lightgray">No indicators created yet</div>
            </div>
        </template>
    </Table>
    <ModalQuestion v-bind:visible="remove !== null" v-on:answer:yes="remove!()" v-on:update:visible="remove = null">
        <template v-slot:title>Are you sure you want to delete {{selected.length}} {{selected.length === 1 ? "indicator" : "indicators"}}?</template>
        <template v-slot:text>Once deleted, you won’t be able to recover {{selected.length === 1 ? "it" : "them"}}.</template>
        <template v-slot:yes>Yes, delete {{selected.length === 1 ? "it" : "them"}}</template>
        <template v-slot:no>No, keep {{selected.length === 1 ? "it" : "them"}}</template>
    </ModalQuestion>
</template>