<script lang="ts" setup>
import {type StyleValue, computed, useAttrs, useTemplateRef} from "vue";

interface Props
{
    class?: any;
    disabled?: boolean;
    placeholder?: string;
    singleline?: boolean;
    style?: StyleValue;
}
const props = withDefaults(defineProps<Props>(),
{
    disabled: false,
    placeholder: "",
    singleline: false
});
const value = defineModel<string>("value", {default: ""});
const getText = () =>
{
    if(props.singleline)
    {
        return value.value.replaceAll(/\n/g, " ");
    }
    else
    {
        return value.value;
    }
};
const setText = (text: string) =>
{
    if(props.singleline)
    {
        value.value = text.replaceAll(/\n/g, " ");
    }
    else
    {
        value.value = text;
    }
};
const text = computed({get: getText, set: setText});
const textarea = useTemplateRef("textarea");
const enter = (e: KeyboardEvent) =>
{
    if(props.singleline)
    {
        const {target} = e;
        if(target instanceof HTMLTextAreaElement)
        {
            const {form} = target;
            form?.dispatchEvent(new Event("submit", {cancelable: true}));
            e.preventDefault();
        }
    }
};
const focus = () => textarea.value?.focus();
defineExpose({focus});
defineOptions({inheritAttrs: false});

const attrs = useAttrs();
</script>
<template>
    <div class="b-color-inherit color-inherit box-border relative">
        <pre class="box-border color-inherit font-sans m-0 overflow-wrap-anywhere p-0 text-body text-transparent w-100% white-space-pre-wrap" v-bind:class="props.class" v-bind:style="style">{{value}}&ZeroWidthSpace;</pre>
        <textarea class="absolute b-0 b-none bg-transparent box-border color-inherit disabled-color-middlegray font-sans h-100% min-h-1lh outline-none overflow-wrap-anywhere p-0 resize-none text-body top-0 w-100%" ref="textarea" v-bind="attrs" v-bind:class="props.class" v-bind:disabled="disabled" v-bind:placeholder="placeholder" v-bind:style="style" v-model="text" v-on:keydown.enter="enter"/>
    </div>
</template>