<script lang="ts" setup>
import {nextTick, onBeforeUnmount, onMounted, ref, useId, useTemplateRef, watchPostEffect} from "vue";

interface Props
{
    disabled?: boolean;
    items?: string[];
    readonly?: boolean;
}
const props = withDefaults(defineProps<Props>(),
{
    disabled: false,
    items: () => [],
    readonly: false
});
const selected = defineModel<number[]>("selected", {default: []});
const add = (index: number) =>
{
    if(selected.value.includes(index))
    {
        remove(index);
    }
    else
    {
        selected.value = [...selected.value, index];
    }
};
const remove = (index: number) =>
{
    selected.value = selected.value.filter((v) => v !== index);
};

const el = useTemplateRef("el");
const popup = ref(false);
const dropdown = () =>
{
    if(props.disabled === false)
    {
        popup.value = !popup.value;
        el.value!.focus();
        nextTick(() => scrollIntoView());
    }
};

const id = useId();
const dismiss = (event: MouseEvent) =>
{
    if(event.target)
    {
        const target = event.target as HTMLElement;
        if(target.closest(`[data-id=${id}]`) === null)
        {
            popup.value = false;
        }
    }
};
onMounted(() => window.addEventListener("click", dismiss));
onBeforeUnmount(() => window.removeEventListener("click", dismiss));

const list = useTemplateRef("list");
const scrollIntoView = () => list.value!.querySelector("div[aria-selected='true']")?.scrollIntoView({behavior: "instant", block: "nearest"});
watchPostEffect(() => nextTick(() => scrollIntoView()));
</script>
<template>
    <div aria-multiselectable class="aria-disabled-color-middlegray outline-none parent relative" ref="el" role="combobox" tabindex="0" v-bind:aria-disabled="disabled" v-bind:data-id="id" v-on:keydown.enter="dropdown" v-on:keydown.space="dropdown">
        <div class="b-b-1 b-b-solid min-h-10 flex flex-items-center flex-justify-between" v-bind:class="[disabled ? '' : 'cursor-pointer']" v-on:mousedown.prevent.stop="dropdown">
            <div>
                <div class="b-1 b-solid box-content cursor-default flex-items-center flex-justify-start rounded-2 inline-flex m-r-2 m-y-1 p-1 text-sm" v-bind:class="[disabled ? '' : '']" v-bind:key="index" v-for="(item, index) in selected.map((n) => items[n])">
                    <span>{{item}}</span>
                    <span class="color-gray hover-color-black i-fa6-solid:circle-xmark m-l-1" v-bind:class="[disabled ? '' : 'cursor-pointer']" v-on:click.stop="remove(selected[index])" v-on:mousedown.stop=""/>
                </div>
            </div>
            <span class="m-l-2 triangle-0.4" v-bind:class="[disabled ? 'color-middlegray' : 'color-black']" v-if="readonly === false"/>
        </div>
        <div class="absolute bg-white block b-1 b-color-verylightgray b-t-0 b-solid b-rd-b-2 box-border font-sans max-h-40 overflow-y-auto shadow transform-origin-t transition-transform w-100% z-1" ref="list" v-bind:class="popup ? 'transform-scale-y-100' : 'transform-scale-y-0'" v-on:transitionstart="scrollIntoView">
            <div class="block box-border color-black cursor-pointer flex flex-items-center font-sans h-10 hover-bg-lightgray p-2" role="option" v-bind:aria-selected="selected.includes(index)" v-bind:class="selected.includes(index) ? 'bg-lightgray' : 'bg-white'" v-bind:key="index" v-bind:value="index" v-for="(item, index) in items" v-on:mousedown="add(index)" v-on:mouseup="popup = false">{{item}}</div>
        </div>
    </div>
</template>