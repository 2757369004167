<script lang="ts" setup>
import {nextTick, onActivated, onMounted, useAttrs, useTemplateRef, watch} from "vue";

interface Props
{
    autofocus?: boolean,
    disabled?: boolean,
    placeholder?: string,
    readonly?: boolean
}
const props = withDefaults(defineProps<Props>(),
{
    autofocus: false,
    disabled: false,
    placeholder: "",
    readonly: false
});
const value = defineModel<string>("value", {default: ""});

const attrs = useAttrs();

const input = useTemplateRef("el");
const focus = () => input.value!.focus();
defineExpose({el: input, focus});

const autofocusing = () =>
{
    if(props.autofocus)
    {
        nextTick(() => focus());
    }
};
onActivated(autofocusing);
onMounted(autofocusing);
watch(() => props.disabled, autofocusing);
</script>
<template>
    <input class="b-none b-b-1 b-b-color-inherit b-b-solid bg-white block box-content disabled-color-middlegray disabled-cursor-default font-sans color-inherit group-[.invalid:not(:hover)]-parent-[.invalid]-color-pink h-10 outline-none p-0 placeholder-color-middlegray read-only-cursor-default enabled-read-only-border-white text-body w-100%" ref="el" type="text" v-bind="attrs" v-bind:autofocus="autofocus" v-bind:disabled="disabled" v-bind:placeholder="placeholder" v-bind:readonly="readonly" v-model="value"/>
</template>