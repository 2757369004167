<script setup lang="ts">
import Card from "../components/cards/SelectionCard.vue";
import {awaitable} from "../components/Awaitable";
import {fetch} from "../API";
import {ref} from "vue";
import {useBreadcrumb} from "../Breadcrumb";
import {useRouter} from "vue-router";
import {useWizardBreadcrumb} from "../Wizard";

interface Props
{
    projectId: string;
}
const props = defineProps<Props>();
const router = useRouter();

const adding = ref(false);
const add = awaitable(adding, async () =>
{
    const {projectId} = props;
    const indicatorId = await fetch("post", "/project/{projectId}/indicator/", {projectId}, {}, null);
    await router.push(`/project/${projectId}/indicator/add/${indicatorId}/`);
});
const gotoAssistant = async () => await router.push(`/project/${props.projectId}/assistant/`);
const gotoSearch = async () => await router.push(`/project/${props.projectId}/indicator/add/search/`);

useBreadcrumb();
useWizardBreadcrumb();
</script>
<template>
    <h1>How would you like to add indicators?</h1>
    <p>To help you effectively measure sustainability and impact, we provide different ways to find and add indicators. You may use one or more of the following ways to introduce indicators into your project.</p>
    <div class="grid grid-gap-4 grid-justify-center sm-grid-rows-2 sm-grid-cols-[max-content_1fr] ">
        <div class="bg-black text-center text-white p-x-4 p-y-1 self-start">GHG related</div>
        <div class="flex flex-gap-4 flex-justify-center flex-wrap">
            <Card icon="i-material-symbols-light:handshake-outline-rounded" v-on:click="gotoAssistant">
                <template v-slot:title>GHG indicator guide</template>
                <template v-slot:description>Go through a guide to get recommendations of GHG Protocol indicators.</template>
            </Card>
            <Card icon="i-material-symbols:search-rounded" v-on:click="gotoSearch">
                <template v-slot:title>Search indicator templates</template>
                <template v-slot:description>Find an indicator template to create your indicator.</template>
            </Card>
        </div>
        <div class="bg-black text-center text-white p-x-4 p-y-1 self-start">General</div>
        <div class="flex flex-gap-4 flex-justify-center flex-wrap">
            <Card icon="i-mdi:target" v-bind:loading="adding" v-on:click="add">
                <template v-slot:title>Add custom indicator</template>
                <template v-slot:description>Create bespoke indicators that suit your organization’s unique needs.</template>
            </Card>
        </div>
    </div>
</template>
