<script lang="ts" setup>
const expanded = defineModel<boolean>("expanded", {default: true});

const toggle = () => expanded.value = !expanded.value;
</script>
<template>
    <div class="b-rd-2 bg-verylightgray transition-padding p-x-6" v-bind:class="expanded ? 'p-y-6' : 'p-y-3'">
        <div class="cursor-pointer flex font-bold justify-between items-center parent" v-on:click="toggle">
            <div class="font-500 font-title text-6">
                <slot name="header"/>
            </div>
            <span class="i-material-symbols:keyboard-arrow-down-rounded parent-hover-text-black text-gray transition-transform text-8" v-bind:class="expanded ? '' : 'rotate--180'"/>
        </div>
        <div class="box-border overflow-y-hidden transition-max-height" v-bind:class="expanded ? 'max-h-15em' : 'max-h-0'">
            <div class="p-t-3">
                <slot/>
            </div>
        </div>
    </div>
</template>
