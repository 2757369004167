import {type MaybeRefOrGetter, type Ref, computed, toRaw, toValue, watch} from "vue";
import type {Path, PathParams, PathQuery, PathResponseBody} from "../../api/main/API";
import {API_URL} from "./Environment";
import {fetch} from "./API";
import {url} from "../../api/main/API";
import useSWRV from "swrv";

export type SWR<T> =
{
    data: Ref<T | undefined>;
    mutate: (value?: T) => Promise<void>;
};

export type Options =
{
    ttl: number;
};

/**
 * Use stale-while-revalidate (SWR) to fetch data from the API server.
 *
 * @param method the HTTP method.
 * @param path the request path.
 * @param params the path parameters.
 * @param query the query parameters.
 * @param body the request body.
 * @returns the response from the API server.
 */
export function useSWR<P extends Path = Path>(path: P, params: MaybeRefOrGetter<PathParams<P, "get">>, query: MaybeRefOrGetter<PathQuery<P, "get">>, {ttl}: Options = {ttl: 2000}): SWR<PathResponseBody<P, "get">>
{
    const key = computed(() => url<P, "get">(API_URL, path, toValue(params), toValue(query)).toString());
    const {data, mutate: mutateCache} = useSWRV(key, async () => await fetch("get", path, toValue(params), toValue(query), null, undefined), {dedupingInterval: ttl, revalidateOnFocus: false});
    watch(key, () => data.value = undefined, {flush: "sync"});
    const mutate = async (value?: PathResponseBody<P, "get">) =>
    {
        if(value !== undefined)
        {
            await mutateCache(() => value);
        }
        else if(data.value !== undefined)
        {
            const mutated = toRaw(data.value);
            await mutateCache(() => mutated);
        }
    };
    return {data, mutate};
}