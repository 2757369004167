<script lang="ts">
import {type Card, components} from "./ReportCards";
import {ReportMode} from "./ReportMode";
import {VueDraggableNext} from "vue-draggable-next";
import {useBreadcrumb} from "../Breadcrumb";

export default
{
    components
};
</script>
<script lang="ts" setup>
interface Props
{
    id?: string;
    mode: ReportMode.Edit | ReportMode.View;
}
withDefaults(defineProps<Props>(), {});
const cards = defineModel<Card[]>("cards", {default: []});
const title = defineModel<string>("title", {default: ""});

const remove = (index: number) => cards.value = cards.value.filter((_, n) => n !== index);
const update = (index: number, value: any) => cards.value = cards.value.map((item, n) => n === index ? {...item, value} : item);

useBreadcrumb({reportId: () => title.value});
</script>
<style scoped>
.ghost-report
{
    opacity: 0.5;
}
</style>
<template>
    <div class="grid grid-rows-[auto_1fr] min-h-100% select-none" v-if="mode === ReportMode.Edit">
        <input aria-label="Title" class="b-1 b-rd-2 b-gray b-dashed bg-transparent box-border font-sans m-b-9 outline-none p-3 text-9 w-100%" type="text" placeholder="Report Title" v-if="mode === ReportMode.Edit" v-model="title"/>
        <VueDraggableNext aria-label="Report elements" class="grid grid-gap-8" v-bind:style="{gridTemplateRows: `repeat(${cards.length}, auto) 1fr`}" fallback-class="rotate-1" handle=".handle" ghost-class="ghost-report" v-bind:force-fallback="true" v-bind:group="id" v-model="cards">
            <div role="article" v-bind:key="index" v-for="(item, index) in cards">
                <Component v-bind:is="item.card" v-bind="item.props ?? {}" v-bind:mode="ReportMode.Edit" v-bind:value="item.value" v-on:remove="remove(index)" v-on:update:value="(value: any) => update(index, value)">
                    <template v-if="item.slot" v-slot:[item.slot.name]>{{item.slot.value}}</template>
                </Component>
            </div>
            <div class="not-last-hidden"/>
        </VueDraggableNext>
    </div>
    <div class="flex flex-col flex-gap-6" v-else>
        <div class="overflow-wrap-anywhere text-8">{{title}}</div>
        <Component v-bind="item.props ?? {}" v-bind:is="item.card" v-bind:key="index" v-bind:mode="ReportMode.View" v-model:value="item.value" v-for="(item, index) in cards">
            <template v-if="item.slot" v-slot:[item.slot.name]>{{item.slot.value}}</template>
        </Component>
    </div>
</template>
