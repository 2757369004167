<script lang="ts" setup>
import type {IndicatorFormulaParameters, Unit} from "../../API";
import Field from "../../components/Field.vue";
import IndicatorUnit from "./IndicatorUnit.vue";
import Tex from "../../components/Tex.vue";
import Textbox from "../../components/Textbox.vue";

interface Props
{
    disabled: boolean;
    names: Set<string>;
    readonly: boolean;
}
withDefaults(defineProps<Props>(), {});
const parameters = defineModel<IndicatorFormulaParameters>("parameters", {required: true});

const getName = (name: string) => parameters.value[name]?.name ?? "";
const setName = (name: string, value: string) =>
{
    const parameter = parameters.value[name] ?? {name: value};
    parameters.value = {...parameters.value, [name]: {...parameter, name: value}};
};

const getUnit = (name: string) => parameters.value[name]?.unit ?? null;
const setUnit = (name: string, value: Unit | null) =>
{
    const parameter = parameters.value[name] ?? {unit: value!};
    parameters.value = {...parameters.value, [name]: {...parameter, unit: value!}};
};
</script>
<template>
    <template v-bind:key="index" v-for="(name, index) in names">
        <div class="b b-b-solid b-color-lightgray text-small">Parameter <Tex type="variable" v-bind:expression="name"/></div>
        <Field class="m-l-3" label="Measurement" rule="NonEmptyString" v-bind:value="getName(name)" v-slot="{id}">
            <Textbox v-bind:disabled="disabled" v-bind:id="id" v-bind:readonly="readonly" v-bind:value="getName(name)" v-on:update:value="(value) => setName(name, value)"/>
        </Field>
        <IndicatorUnit class="m-l-3" v-bind:disabled="disabled" v-bind:optional="true" v-bind:readonly="readonly" v-bind:unit="getUnit(name)" v-on:update:unit="(value) => setUnit(name, value)"/>
    </template>
</template>