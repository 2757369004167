<script generic="Dummy" lang="ts" setup>
import {computed, useId} from "vue";

interface Props
{
    disabled?: boolean,
    label?: string,
    name?: string,
    radio?: boolean
};
interface Slots
{
    default: (_: {}) => any
};
const props = withDefaults(defineProps<Props>(),
{
    disabled: false,
    label: undefined,
    radio: false
});
const slots = defineSlots<Slots>();
const checked = defineModel<boolean>("value", {default: false});
const id = useId();
const type = computed(() => props.radio ? "radio" : "checkbox");
</script>
<template>
    <input class="appearance-none b-1 b-color-current b-solid before-content before-color-green before-scale-0 before-transition-transform box-border color-current cursor-inherit disabled-before-color-middlegray flex flex-items-center flex-justify-center h-6 m-0 max-w-6 min-w-6 outline-0" v-bind:class="radio ? 'b-rd-4 before-checked-scale-80% before-i-fa6-solid:circle text-4' : 'b-rd-1 before-checked-scale-100% before-i-fa6-solid:check text-5'" v-bind:disabled="disabled" v-bind:id="id" v-bind:name="name" v-bind:type="type" v-model="checked" v-on:click.stop="" v-if="label === undefined && slots.default === undefined"/>
    <div class="color-gray flex-inline flex-items-center hover-color-black max-w-100%" v-bind:class="[disabled ? 'color-middlegray' : 'cursor-pointer']" v-else>
        <input class="appearance-none b-1 b-color-current b-solid before-content before-color-green before-scale-0 before-transition-transform box-border color-current cursor-inherit disabled-before-color-middlegray flex flex-items-center flex-justify-center h-6 m-0 max-w-6 min-w-6 outline-0" v-bind:class="radio ? 'b-rd-4 before-checked-scale-80% before-i-fa6-solid:circle text-4' : 'b-rd-1 before-checked-scale-100% before-i-fa6-solid:check text-5'" v-bind:disabled="disabled" v-bind:id="id" v-bind:name="name" v-bind:type="type" v-model="checked" v-on:click.stop=""/>
        <label class="cursor-inherit flex flex-items-center h-100% not-empty-p-l-2" v-bind:for="id">
            <slot>{{label}}</slot>
        </label>
    </div>
</template>